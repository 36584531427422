/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Divider, Typography } from '@mui/material'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import ModalStyled from '../../../elements/ModalStyled'
import Chain from '@app/libs/api-wallet-client/api/chain'
import { Currency } from '@app/typings/core'
import ChainItem, { Props as ChainItemProps } from './chains/ChainItem'

interface Props extends ChainItemProps {
  isVisible: boolean
  chains: Chain[]
  onSelect(currency: Currency): any
  onClose(): any
  preventDisabledState?: boolean
}
function ModalChainSelect(props: Props) {
  const { isVisible, onClose, onSelect, chains, preventDisabledState, type, currency } = props
  // const [search, setSearch] = useState('')

  // const dispatch = useDispatch<ReduxDispatch>()
  //
  // const handleChange = (e) => {
  //   setSearch(e.target.value || '')
  // }

  const handleSelect = (currency: Currency) => {
    onSelect(currency)
    onClose()
  }

  const chainsOrdered = useMemo(() => {
    return _.orderBy(chains, (chain) => chain.is_enabled, 'desc')
  }, [chains])

  return (
    <ModalStyled
      isVisible={isVisible}
      onClose={onClose}
      showCloseButton
      css={css`
        min-width: 400px;
        padding-top: 10px;
      `}
    >
      <div>
        <Typography
          css={css`
            font-weight: bold;
            font-size: 16px;
          `}
        >
          Select Network
        </Typography>
      </div>

      <Divider
        css={css`
          margin: 5px 0 10px;
        `}
      />

      <div
        css={css`
          height: 300px;
          margin: 0 -20px;
        `}
      >
        {_.map(chainsOrdered, (item) => (
          <div key={`modal-chains-item-${item.id}`}>
            <ChainItem
              chain={item}
              onSelect={handleSelect}
              preventDisabledState={preventDisabledState}
              type={type}
              currency={currency}
            />
          </div>
        ))}
      </div>
    </ModalStyled>
  )
}

export default ModalChainSelect
