/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import React, { useMemo } from 'react'
import useWalletQuantityAvailable from '../../../../../../hooks/wallet/useWalletQuantityAvailable'
import { useOrderCreateMarketContext } from '../../OrderCreateContext'
import StyledOrderFormButton from './components/StyledOrderFormButton'

interface Props {
  type: 'BUY' | 'SELL'
  total: number | string
  quantity: number | string
  error?: string
  onExecute(order_type: 'BUY' | 'SELL'): any
}

function TradeOrderFormButton(props: Props) {
  const { type, total, quantity, error, onExecute } = props

  const { market, walletMain, walletSecondary } = useOrderCreateMarketContext()

  const mainWalletAvailable = useWalletQuantityAvailable(walletMain)
  const secondWalletAvailable = useWalletQuantityAvailable(walletSecondary)

  const handlePressBuy = () => onExecute('BUY')
  const handlePressSell = () => onExecute('SELL')

  const isEnough = useMemo(() => {
    // in future we need to show Tooltip instead of disabling button
    if (!total) return false

    if (Number(total) < 3) return null // min order must be 3 USD

    if (type === 'BUY') {
      return Number(secondWalletAvailable) >= Number(total)
    }
    if (type === 'SELL') {
      return Number(mainWalletAvailable) >= Number(quantity)
    }

    console.error('order type is not defined')
    return false
  }, [type, total, walletMain, walletSecondary])

  return (
    <div>
      <StyledOrderFormButton
        type={type}
        market={market}
        onClickBuy={handlePressBuy}
        onClickSell={handlePressSell}
        disabled={!isEnough}
      />
      <Typography
        color={'error'}
        css={css`
          font-size: 12px;
        `}
        textAlign={'center'}
      >
        {error}
      </Typography>
    </div>
  )
}

export default TradeOrderFormButton
