/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import AppBar from '../../components/appBar/AppBar'
import EmailVerifyToolbar from './components/EmailVerifyToolbar'
import WalletList from './overwiew/WalletList'
import ProfileTabs from './tabs/ProfileTabs'

const cssButton = css`
  padding: 7px 20px;
  background-color: rgb(234, 236, 239);
  border-radius: 7px;
  margin-left: 10px;

  &:hover {
    background-color: rgb(240, 240, 240);
  }
`

function WalletOverview() {
  return (
    <div
      css={css`
        min-height: 700px;
      `}
    >
      <AppBar />

      <Grid
        container
        css={css`
          padding-top: 20px;
        `}
      >
        <ProfileTabs />
        <Grid item flexGrow={1}>
          <Grid container justifyContent={'center'}>
            <div
              css={css`
                max-width: 1000px;
                width: 100%;
              `}
            >
              <EmailVerifyToolbar />

              <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Typography
                  variant={'h1'}
                  css={css`
                    font-size: 20px;
                  `}
                >
                  Wallet Overview
                </Typography>

                <Grid item>
                  <Grid container alignItems={'center'}>
                    <Link to={'/wallet/deposit'}>
                      <div
                        css={[
                          cssButton,
                          css`
                            background-color: rgb(252, 213, 53);
                            &:hover {
                              background-color: rgba(252, 213, 53, 0.9);
                            }
                          `,
                        ]}
                      >
                        <Typography fontWeight={'bold'}>Deposit</Typography>
                      </div>
                    </Link>

                    <Link to={'/wallet/withdraw'}>
                      <div css={cssButton}>
                        <Typography fontWeight={'bold'}>Withdraw</Typography>
                      </div>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>

              <WalletList />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default WalletOverview
