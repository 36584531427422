import { Grid } from '@mui/material'
import { ReduxState } from '@reducers/index'
import OrderDispatcher from '@app/reducers/orders/dispatcher'
import _ from 'lodash'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import Loader from '../../../components/Loader'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import OrderList from './orderList/OrderList'

function TradeOrderListHistory() {
  const dispatch = useDispatch<ReduxDispatch>()
  const actionGetOrders = useAsyncFetch(async () => {
    await dispatch(OrderDispatcher.getHistoryOrders({ isForce: false }))
  })
  const { list } = useSelector((state: ReduxState) => ({
    list: state.orders.listHistory,
  }))

  return (
    <RendererStatusSplit
      statuses={actionGetOrders}
      isEmpty={_.isEmpty(list)}
      renderLoading={() => (
        <Grid container justifyContent={'center'}>
          <Loader />
        </Grid>
      )}
      render={() => <OrderList list={list} />}
    />
  )
}

export default TradeOrderListHistory
