/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import PriceWithIndicator from '../../../components/price/PriceWithIndicator'
import { Currency, Market } from '@app/typings/core'
import CurrencyLogo from '../../markets/components/CurrencyLogo'
import HomePreviewContainer from './HomePreviewContainer'

interface Props {
  market: Market
}
function MarketItemPreview(props: Props) {
  const { market } = props
  const { key, currency, price } = market
  const { name, symbol, logo } = (currency as Currency) || {}

  console.log('market', market)

  return (
    <Link to={`/trade/${key}`}>
      <HomePreviewContainer>
        <Grid container alignItems={'center'}>
          <CurrencyLogo currency={currency} />
          <div
            css={css`
              padding-left: 10px;
            `}
          >
            <Typography fontWeight={'bold'}>{symbol}</Typography>
            {/* <Typography */}
            {/*  css={css` */}
            {/*    font-size: 10px; */}
            {/*    color: #ccc; */}
            {/*  `} */}
            {/* > */}
            {/*  {name} */}
            {/* </Typography> */}
          </div>
          <div
            css={css`
              padding: 0 10px;
            `}
          >
            <PriceWithIndicator price={price} />
          </div>
        </Grid>
      </HomePreviewContainer>
    </Link>
  )
}

export default MarketItemPreview
