/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography, Grid } from '@mui/material'
import Button from '@mui/material/Button'
import { emailApi } from '@userino/user-api'
import invariant from 'invariant'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useNavigate, useParams } from 'react-router-dom'
import colors from '../../init/colors'
import Loader from '../../components/Loader'
import AppBar from '../../components/appBar/AppBar'
import RendererActionSplit from '../../libs/RendererActionSplit'

function EmailVerify() {
  const params = useParams<{ code: string }>()
  const navigate = useNavigate()

  const actionVerify = useAsyncFetch(async () => {
    const { code } = params
    invariant(code, 'invalid verification')
    const res = await emailApi.verifyEmail({ code })
    console.log('verify res', res)
  })

  return (
    <div>
      <AppBar />

      <Grid container justifyContent={'center'}>
        <div
          css={css`
            padding-top: 20px;
          `}
        >
          <Typography
            variant={'h1'}
            css={css`
              margin-top: 50px;
              margin-bottom: 30px;
            `}
          >
            Verifying email address
          </Typography>
          <RendererActionSplit
            statuses={actionVerify}
            renderLoading={() => (
              <Grid container justifyContent={'center'}>
                <Loader />
              </Grid>
            )}
            renderError={(error) => (
              <Typography color={'error'} textAlign={'center'}>
                {error}
              </Typography>
            )}
            renderSuccess={() => (
              <Grid container flexDirection={'column'} alignItems={'center'}>
                <Grid container alignItems={'center'} justifyContent={'center'}>
                  <FontAwesomeIcon icon={faCheckCircle} color={colors.green} fontSize={24} />
                  <Typography
                    css={css`
                      margin-left: 10px;
                      font-size: 16px;
                    `}
                  >
                    Email is verified
                  </Typography>
                </Grid>

                <Button
                  variant={'contained'}
                  onClick={() => navigate('/', { replace: true })}
                  css={css`
                    margin-top: 40px;
                  `}
                >
                  Close
                </Button>
              </Grid>
            )}
            render={() => <Typography textAlign={'center'}>Email Verification</Typography>}
          />
        </div>
      </Grid>
    </div>
  )
}

export default EmailVerify
