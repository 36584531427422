/** @jsx jsx */
import MarketDispatcher from '@app/reducers/markets/dispatcher'
import { jsx, css } from '@emotion/react'
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import { ReduxState } from '@reducers/index'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { useParams } from 'react-router-dom'
import Loader from '../../components/Loader'
import AppBar from '../../components/appBar/AppBar'
import useCurrency from '../../hooks/useCurrency'
import useIsLoggedIn from '../../hooks/useIsLoggedIn'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import TradeChart from './trade/TradeChart'
import TradeHead from './trade/TradeHead'
import TradeOrderBook from './trade/TradeOrderBook'
import TradeOrderCreate from './trade/TradeOrderCreate'
import TradeOrderList from './trade/TradeOrderList'

import '@app/socket/listenSocketEvents'
import useMarketSubscription from '@app/socket/hooks/useMarketSubscription'

function Trade() {
  const { key } = useParams<{ key: string }>()
  const dispatch = useDispatch<ReduxDispatch>()
  const isLoggedIn = useIsLoggedIn()

  const { market } = useSelector((state: ReduxState) => ({
    market: state.markets[key as string],
  }))
  const fetchMarket = useAsyncFetch(async () => {
    if (!key) return null
    await dispatch(MarketDispatcher.get({ market_key: key }))
  }, [key])

  useAsyncFetch(async () => {
    if (!market) return null

    await dispatch(MarketDispatcher.getOrderBook({ market_id: market.id }))
  }, [market?.id])

  const currencySecondary = useCurrency(market?.currency_secondary_id)

  useMarketSubscription(market?.key)

  return (
    <div
      css={css`
        min-height: 700px;
        padding-bottom: 50px;
      `}
    >
      <AppBar />
      <RendererStatusSplit
        statuses={fetchMarket}
        isEmpty={!market}
        renderEmpty={() => (
          <Typography color="error" textAlign={'center'}>
            Market is not found
          </Typography>
        )}
        renderLoading={() => (
          <Grid container justifyContent={'center'}>
            <Loader />
          </Grid>
        )}
        render={() => (
          <Grid>
            <TradeHead market={market} currencySecondary={currencySecondary} />

            <Grid container>
              <Grid item md={7}>
                <TradeChart market={market} />

                {isLoggedIn && <TradeOrderList />}
              </Grid>
              <Grid item md={5}>
                <Grid container justifyContent={'center'}>
                  <Grid item md={6}>
                    <TradeOrderBook market={market} />
                  </Grid>
                  <Grid item md={6}>
                    <TradeOrderCreate market={market} currencySecondary={currencySecondary} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      />
    </div>
  )
}

export default Trade
