/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import Button from '@mui/material/Button'
import React from 'react'
import { Link } from 'react-router-dom'

interface Props {}

function TradeOrderLoginLinks(props: Props) {
  return (
    <div>
      <Link to={'/signup'}>
        <Button variant={'contained'} color={'primary'} fullWidth sx={{ marginBottom: '20px', marginTop: '20px' }}>
          Register
        </Button>
      </Link>

      <Link to={'/login'}>
        <Button variant={'contained'} color={'secondary'} fullWidth>
          Login
        </Button>
      </Link>
    </div>
  )
}

export default TradeOrderLoginLinks
