import { Grid, Typography } from '@mui/material'
import MarketDispatcher from '@app/reducers/markets/dispatcher'
import _ from 'lodash'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import useLimitedArray from '../../../hooks/useLimittedArray'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import HomePreviewContainer from '../components/HomePreviewContainer'
import MarketItemPreview from '../components/MarketItemPreview'

function HomeMarkets() {
  const { list } = useSelector((state) => ({
    list: state.lists.marketsPopular,
  }))

  const dispatch = useDispatch<ReduxDispatch>()
  useAsyncFetch(async () => {
    await dispatch(MarketDispatcher.getPopularList())
  }, [])

  const listLimited = useLimitedArray(list, 3)

  return (
    <div>
      <Grid container alignItems={'center'}>
        {_.map(listLimited, (item) => (
          <MarketItemPreview market={item} key={`home-market-popular-${item.id}`} />
        ))}

        <Link to={'/markets'}>
          <HomePreviewContainer>
            <Typography>See all</Typography>
          </HomePreviewContainer>
        </Link>
      </Grid>
    </div>
  )
}

export default HomeMarkets
