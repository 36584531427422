/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import AuthDispatcher from '@app/reducers/auth/dispatcher'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { RendererStatusSplit } from 'react-renderer-status-split'
import validator from 'validator'
import Loader from '../../../../components/Loader'
import BootstrapInput from '../../../../elements/form/BootstrapInput'
import { useAppDispatch } from '../../../../libs/redux'

interface Props {
  onLoginSuccess(): any
}

function LoginCredentialsForm(props: Props) {
  const { onLoginSuccess } = props
  const form = useForm<{ email: string; password: string }>({
    reValidateMode: 'onSubmit',
  })
  const {
    formState: { errors },
  } = form

  const dispatch = useAppDispatch()
  const submitAction = useAsyncHandler(async (params: { email: string; password: string }) => {
    await dispatch(AuthDispatcher.loginWithEmailAndPassword(params))
    onLoginSuccess()
  })

  const onSubmit = form.handleSubmit(submitAction.execute)

  return (
    <div>
      <BootstrapInput
        {...form.register('email', {
          required: true,
          validate(email) {
            return validator.isEmail(email)
          },
        })}
        title={'Email'}
        type="email"
        placeholder={'Email'}
        fullWidth
        error={!!errors.email}
        sx={{
          marginBottom: '15px',
        }}
      />
      <BootstrapInput
        {...form.register('password', { required: true, min: 8, max: 100 })}
        title={'Password'}
        type="password"
        placeholder={'Password'}
        error={!!errors.password}
        fullWidth
      />
      <RendererStatusSplit
        statuses={submitAction}
        renderLoading={() => (
          <Grid container alignItems={'center'} justifyContent={'center'}>
            <Loader />
          </Grid>
        )}
        render={() => (
          <div
            css={css`
              padding-top: 20px;
              padding-bottom: 20px;
            `}
          >
            <Button onClick={onSubmit} variant={'contained'} color={'primary'} fullWidth>
              Login
            </Button>
            {submitAction.error && <Typography color={'error'}>{submitAction.error}</Typography>}
          </div>
        )}
      />
    </div>
  )
}

export default LoginCredentialsForm
