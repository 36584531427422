/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'

const cssCell = css`
  font-size: 12px;
  color: rgb(112, 122, 138);
`

function OrderListTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell css={cssCell}>Date</TableCell>
        <TableCell css={cssCell}>Pair</TableCell>
        <TableCell css={cssCell}>Type</TableCell>
        <TableCell css={cssCell}>Side</TableCell>
        <TableCell css={cssCell}>Price</TableCell>
        <TableCell css={cssCell}>Amount</TableCell>
        <TableCell css={cssCell}>Filled</TableCell>
        <TableCell css={cssCell}>Total</TableCell>
        <TableCell css={cssCell}>Trigger Conditions</TableCell>
        <TableCell css={cssCell} align="right">
          Cancel
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default OrderListTableHead
