import { CandlestickData } from 'lightweight-charts'
import _ from 'lodash'
import { useMemo } from 'react'
import { candleIntervalMinuteMapping } from '../../../typings/candles'
import { MarketCandleInterval } from '@app/typings/core'

function useCandlesWithFilledEmptySpaces({
  candlesInterval,
  candles,
}: {
  candles: CandlestickData[]
  candlesInterval: MarketCandleInterval
}): CandlestickData[] {
  const step = useMemo(() => {
    const minutes = candleIntervalMinuteMapping[candlesInterval]
    if (!minutes) return null

    return minutes * 60 // * 1000
  }, [candlesInterval])

  const candlesFull = useMemo(() => {
    // fill empty spaces between existing candles
    if (!step) return []
    const listAll: CandlestickData[] = []

    _.forEach(candles, (candle, i) => {
      const candlePrev = candles[i - 1]
      if (candlePrev) {
        let timeCurrent = (candlePrev.time as number) + step
        while (timeCurrent < candle.time) {
          listAll.push({
            time: timeCurrent,
            open: candlePrev.close,
            close: candlePrev.close,
            high: candlePrev.close,
            low: candlePrev.close,
          })

          timeCurrent += step
        }
      }

      listAll.push(candle)
    })

    return listAll
  }, [candles, step])

  return candlesFull
}

export default useCandlesWithFilledEmptySpaces
