import React from 'react'
import { Order } from '@app/typings/core'
import { useOrderCreateMarketContext } from '../OrderCreateContext'
import FormInput from '../components/FormInput'
import { OrderFormValues } from '../hooks/useOrderFormValues'

interface Props {
  type: Order['order_type']
  form: OrderFormValues
}

function OrderLimitForm(props: Props) {
  const { form, type } = props
  const { price, total, quantity, onTotalChange, onPriceChange, onQuantityChange } = form

  const { market, walletSecondary } = useOrderCreateMarketContext()
  const { currency } = market

  return (
    <div>
      <FormInput
        id={'order-create-price'}
        value={price}
        onChange={onPriceChange}
        title={'Price'}
        currency={walletSecondary}
      />

      <FormInput
        id={'order-create-amount'}
        value={quantity}
        onChange={onQuantityChange}
        title={'Quantity'}
        currency={currency}
      />
      <FormInput
        id={'order-total-amount'}
        value={total}
        onChange={onTotalChange}
        title={'Total'}
        currency={walletSecondary}
      />
    </div>
  )
}

export default OrderLimitForm
