import constantManager from 'constant-manager'

const currencyTypes = constantManager(
  {
    SET_LIST: '',
  },
  {
    prefix: 'CURRENCIES@',
  },
)

export default currencyTypes
