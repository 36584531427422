import { initClient } from '@userino/user-api'
import apiClient from './app/api/apiClient'
import config from './config'

apiClient.defaults.baseURL = config.api_url

initClient({
  url: config.userino.url,
  app_id: config.userino.app_id,
})
