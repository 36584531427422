/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import React, { useMemo } from 'react'
import Moment from 'react-moment'
import { StyledTableCell, StyledTableRow } from '../../../../../elements/table/TableBlack'

interface Props {
  item: any
}
export default function TransactionHistoryItem(props: Props) {
  const { item } = props
  const { status, amount, address, created_at } = item || {}
  // add tx_id,

  let statusColor = useMemo(() => {
    if (status === 'active') return '#6e94f4'
    if (status === 'canceled') return '#686868'
    return '#323232'
  }, [status])

  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell align="left">
          <Typography>{address}</Typography>
        </StyledTableCell>

        <StyledTableCell align="center">
          <Typography fontWeight={'bold'}>{amount}</Typography>
        </StyledTableCell>

        {/* {!hideNavigationCell && ( */}
        {/*  <StyledTableCell align="center"> */}
        {/*    <Typography> */}
        {/*      <Link to={`/client/${client_id}`}>{clientShort}...</Link> */}
        {/*    </Typography> */}
        {/*  </StyledTableCell> */}
        {/* )} */}

        <StyledTableCell align="center">
          <Typography style={{ color: statusColor }}>{status}</Typography>
        </StyledTableCell>

        <StyledTableCell align="right">
          <Typography>
            <Moment format={'DD MMM, HH:mm'}>{created_at}</Moment>
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  )
}
