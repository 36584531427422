/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Container, Link } from '@mui/material'
import Typography from '@mui/material/Typography'
import React from 'react'
import AppBar from '../../components/appBar/AppBar'

function TokenInfoPage() {
  return (
    <div>
      <AppBar />

      <Container
        css={css`
          padding-top: 20px;
        `}
      >
        <Typography
          variant={'h1'}
          css={css`
            margin-bottom: 30px;
          `}
        >
          Main utility token
        </Typography>

        <div>
          <Typography
            variant={'h2'}
            css={css`
              margin-bottom: 10px;
            `}
          >
            Main utilities:
          </Typography>
          <div>
            <Typography fontWeight={'bold'}>- half of fees from trades will be distributed to token holders</Typography>
            <Typography>- ability to participate in exclusive events</Typography>
            <Typography>- community dao, where you can vote for new listing tokens and other activities</Typography>
            <Typography>- airdrops</Typography>
            <Typography>- coming soon...</Typography>

            {/* <Typography */}
            {/*  css={css` */}
            {/*    font-size: 12px; */}
            {/*    margin-top: 10px; */}
            {/*    color: #908f8f; */}
            {/*    margin-left: 20px; */}
            {/*  `} */}
            {/* > */}
            {/*  there will be more utility in future. */}
            {/* </Typography> */}
          </div>
        </div>

        <div>
          <Typography
            variant={'h2'}
            css={css`
              margin-top: 30px;
              margin-bottom: 10px;
            `}
          >
            When TGE?
          </Typography>
          <div>
            <Typography>Date of TGE is not defined at the moment.</Typography>
            <Typography>
              We will inform you long time before it can happen. It can be only when project will be ready to use for
              masses. We continue work to make it happen for the moment.
            </Typography>
          </div>
        </div>

        <div>
          <Typography
            variant={'h2'}
            css={css`
              margin-top: 30px;
              margin-bottom: 10px;
            `}
          >
            Links
          </Typography>

          <div>
            <Link
              href={
                'https://docs.google.com/spreadsheets/d/1hFfQGsoYAT1Kd_XsApDiV-gmBQaIhnJJ889BSk_cXfQ/edit?usp=sharing'
              }
              target={'_blank'}
            >
              Tokenomics
            </Link>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default TokenInfoPage
