/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import React from 'react'

interface Props {}
function OrderBookHead(props: Props) {
  return (
    <Grid
      container
      alignItems={'center'}
      justifyContent={'space-between'}
      css={css`
        margin-bottom: 7px;

        .MuiTypography-root {
          font-size: 12px;
          color: #a4a4a4;
          font-weight: bold;
        }

        & > .MuiGrid-item {
          flex: 1 0 0;
        }
      `}
    >
      <Grid item flexGrow={2}>
        <Typography>Price</Typography>
      </Grid>

      <Grid item>
        <Typography textAlign={'right'}>Quantity</Typography>
      </Grid>

      <Grid item>
        <Typography textAlign={'right'}>Total</Typography>
      </Grid>
    </Grid>
  )
}

export default OrderBookHead
