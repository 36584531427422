/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Container } from '@mui/material'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'
import { KeyContextProvider } from 'use-key-context'
import AppBar from '../../components/appBar/AppBar'
import MarketsTable from './table/MarketsTable'

function Markets() {
  return (
    <KeyContextProvider value={'markets'}>
      <div
        css={css`
          padding-bottom: 100px;
        `}
      >
        <AppBar />
        <Container>
          <Typography
            variant={'h1'}
            css={css`
              margin-top: 30px;
              margin-bottom: 20px;
            `}
          >
            Markets
          </Typography>

          <MarketsTable />
        </Container>
      </div>
    </KeyContextProvider>
  )
}

export default Markets
