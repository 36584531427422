/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import WalletDispatcher from '@app/reducers/wallet/dispatcher'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import UserAssetsTable from '../assetTable/UserAssetsTable'

function WalletList() {
  const dispatch = useDispatch<ReduxDispatch>()
  const fetchAction = useAsyncFetch(async () => {
    await dispatch(WalletDispatcher.getList())
  })

  return (
    <div
      css={css`
        margin-top: 10px;
      `}
    >
      <UserAssetsTable />
    </div>
  )
}

export default WalletList
