import React from 'react'
import { Order } from '@app/typings/core'
import { useOrderCreateMarketContext } from '../OrderCreateContext'
import FormInput from '../components/FormInput'
import { OrderFormValues } from '../hooks/useOrderFormValues'

interface Props {
  type: Order['order_type']
  form: OrderFormValues
}

function OrderMarketForm(props: Props) {
  const { form, type } = props
  const { total, quantity, onTotalChange, onQuantityChange } = form

  const { market, walletSecondary } = useOrderCreateMarketContext()
  const { currency } = market

  return (
    <div>
      {type === 'BUY' ? (
        <FormInput
          id={'order-create-amount'}
          value={quantity}
          onChange={onQuantityChange}
          title={'Quantity'}
          currency={currency}
        />
      ) : (
        <FormInput
          id={'order-total-amount'}
          value={total}
          onChange={onTotalChange}
          title={'Total'}
          currency={walletSecondary}
        />
      )}
    </div>
  )
}

export default OrderMarketForm
