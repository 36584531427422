/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TableCell, Typography } from '@mui/material'
import OrderDispatcher from '@app/reducers/orders/dispatcher'
import React from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import colors from '../../../../init/colors'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import { Order } from '@app/typings/core'

interface Props {
  order: Order
}

function OrderListItemStatus(props: Props) {
  const { order } = props
  const { id, status } = order

  const dispatch = useDispatch<ReduxDispatch>()
  const deleteAction = useAsyncHandler(async () => {
    if (!confirm('Are you sure want to cancel this order?')) return null
    await dispatch(OrderDispatcher.cancelOrder(id))
  })

  const isActiveOrder = status === 'ACTIVE' || status === 'PENDING'
  let statusColor = '#333'
  if (status === 'COMPLETED') statusColor = colors.statusSuccess
  if (status === 'CANCELED') statusColor = colors.statusCanceled

  return (
    <div>
      {isActiveOrder ? (
        <div
          onClick={deleteAction.execute}
          css={css`
            cursor: pointer;
          `}
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </div>
      ) : (
        <Typography
          css={css({
            fontSize: '12px',
            color: statusColor,
          })}
        >
          {status}
        </Typography>
      )}
    </div>
  )
}

export default OrderListItemStatus
