import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { StringParam, useQueryParam } from 'use-query-params'
import { Currency } from '@app/typings/core'

function useSelectedCurrency(): [Currency, (newValue: string) => void] {
  const [currency_id, setSelectedCurrencyId] = useQueryParam('currency', StringParam)

  const { list } = useSelector((state: ReduxState) => ({
    list: state.currencies.list,
  }))

  const itemSelected = useMemo(() => {
    return _.find(list, (item) => item.id === currency_id) as Currency
  }, [list, currency_id])

  useEffect(() => {
    if (itemSelected) return
    const item = list[0]
    if (item) {
      setSelectedCurrencyId(item.id)
    }
  }, [list])

  return [itemSelected, setSelectedCurrencyId]
}

export default useSelectedCurrency
