/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { faWallet } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  title: string
}

function WalletBreadcrumbs(props: Props) {
  const { title } = props

  return (
    <Grid container alignItems={'flex-end'}>
      <div>
        <Link to={'/wallet'}>
          <Grid container alignItems={'flex-end'}>
            <FontAwesomeIcon
              icon={faWallet}
              css={css`
                font-size: 32px;
                color: #323232;
                margin-right: 10px;
              `}
            />
            <Typography
              variant={'h1'}
              css={css`
                font-size: 20px;
                line-height: 30px;
              `}
            >
              Wallet
            </Typography>
          </Grid>
        </Link>
      </div>

      <div>
        <span css={css`
          margin: 0 15px;
          font-size: 18px;
          line-height: 24px;`
        }
        > > </span>
      </div>

      <div>
        <Typography
          css={css`
            font-size: 20px;
            line-height: 24px;
          `}
        >
          {title}
        </Typography>
      </div>
    </Grid>
  )
}

export default WalletBreadcrumbs
