import apiClient from '@app/api/apiClient'

async function getList() {
  const { data } = await apiClient.get('/wallet/list')
  return data
}

async function get(currency_id: string) {
  const { data } = await apiClient.get(`/wallet/${currency_id}`)
  return data
}

export interface WalletWithdrawParams {
  chain_id: string
  amount: number | string
  address: string
  fee: string
}
async function withdraw(currency_id: string, params: WalletWithdrawParams) {
  const { data } = await apiClient.post(`/wallet/${currency_id}/withdraw`, params)
  return data
}

async function getWithdrawHistory(currency_id: string) {
  const { data } = await apiClient.get(`/wallet/${currency_id}/withdrawals`)
  return data
}

async function checkTransactionRequest(params: { chain_id?: string; tx: string }) {
  const { data } = await apiClient.post(`/wallet/check-transaction`, params)
  return data
}

export default {
  get,
  getList,
  withdraw,
  getWithdrawHistory,
  checkTransactionRequest,
}
