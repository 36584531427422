/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Divider, Typography } from '@mui/material'
import CurrencyDispatcher from '@app/reducers/currencies/dispatcher'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import ModalStyled from '../../../elements/ModalStyled'
import BootstrapInput from '../../../elements/form/BootstrapInput'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import { Currency } from '@app/typings/core'
import CurrencyItem from './currencies/CurrencyItem'

interface Props {
  isVisible: boolean
  title: string
  onSelect(currency: Currency): any
  onClose(): any
}
function ModalCurrencySelect(props: Props) {
  const { isVisible, onClose, onSelect, title } = props
  const [search, setSearch] = useState('')

  const dispatch = useDispatch<ReduxDispatch>()
  const fetchAction = useAsyncFetch(async () => {
    await dispatch(CurrencyDispatcher.getList())
  })

  const { list } = useSelector((state: ReduxState) => ({
    list: state.currencies.list,
  }))

  const handleChange = (e) => {
    setSearch(e.target.value || '')
  }

  const handleSelect = (currency: Currency) => {
    onSelect(currency)
    onClose()
  }

  const listFiltered = useMemo(() => {
    if (!search) return list
    const searchLow = search.toLowerCase()

    return _.filter(list, (item) => {
      const nLow = item.name.toLowerCase()
      const sLow = item.symbol.toLowerCase()
      return nLow.includes(searchLow) || sLow.includes(searchLow)
    })
  }, [search, list])

  return (
    <ModalStyled
      isVisible={isVisible}
      onClose={onClose}
      showCloseButton
      css={css`
        min-width: 400px;
        padding-top: 10px;
        padding-bottom: 0;
      `}
    >
      <div>
        <Typography
          css={css`
            font-weight: bold;
            font-size: 16px;
          `}
        >
          {title}
        </Typography>
      </div>

      <Divider
        css={css`
          margin: 5px 0 10px;
        `}
      />

      <div>
        <BootstrapInput placeholder={'Search'} fullWidth value={search} onChange={handleChange} />
      </div>
      <div
        css={css`
          height: 300px;
          margin: 0 -20px;
          overflow-y: auto;
          padding-bottom: 20px;
        `}
      >
        {_.map(listFiltered, (currency) => (
          <div key={`modal-currencies-item-${currency.id}`}>
            <CurrencyItem currency={currency} onSelect={handleSelect} />
          </div>
        ))}
      </div>
    </ModalStyled>
  )
}

export default ModalCurrencySelect
