import apiClient from '../../api/apiClient'

async function getById(id: string) {
  const { data } = await apiClient.get(`/currency/${id}`)
  return data
}

export default {
  getById,
}
