/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { faUpLong, faDownLong } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import colors from '../../init/colors'

interface Props {
  price: number | string
  className?: string
  showArrows?: boolean
  showDollar?: boolean
}
function PriceWithIndicator(props: Props) {
  const { price, className, showArrows, showDollar } = props
  const priceRef = useRef<number>()
  const [activeIndicator, setActiveIndicator] = useState<'down' | 'up' | null>()

  useEffect(() => {
    const priceN = Number(price)
    const pricePrev = priceRef.current
    if (!pricePrev) return

    if (pricePrev > priceN) {
      setActiveIndicator('down')
    }
    if (pricePrev < priceN) {
      setActiveIndicator('up')
    }

    priceRef.current = priceN
  }, [price])

  // for testing
  // useEffect(() => {
  //   setActiveIndicator('down')
  // }, [])

  useEffect(() => {
    // debounced reset indicator to default like Binance do. They do ~300-500ms
    if (!activeIndicator) return
    const timer = setTimeout(() => setActiveIndicator(null), 2000)

    return () => {
      clearTimeout(timer)
    }
  }, [activeIndicator])

  let valueStr = price
  if (showDollar && valueStr) valueStr = `$${valueStr}`

  return (
    <Grid container alignItems={'center'}>
      <Typography
        css={[
          activeIndicator === 'up' &&
            css`
              color: ${colors.upColor} !important;
            `,

          activeIndicator === 'down' &&
            css`
              color: ${colors.downColor} !important;
            `,
        ]}
        className={className}
      >
        {valueStr}
      </Typography>

      {showArrows ? (
        <span
          css={css`
            font-size: 10px;
            margin-left: 3px;
            display: inline-block;
          `}
        >
          {activeIndicator === 'up' ? <FontAwesomeIcon icon={faUpLong} color={colors.upColor} /> : null}
          {activeIndicator === 'down' ? <FontAwesomeIcon icon={faDownLong} color={colors.downColor} /> : null}
        </span>
      ) : null}
    </Grid>
  )
}

export default PriceWithIndicator
