/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import _ from 'lodash'
import React, { useMemo } from 'react'
import useTotal from '../../../../hooks/math/useTotal'
import { OrderBookItem } from '@app/hooks/view/useOrderBookArrays'

interface Props {
  type: 'SELL' | 'BUY'
  quantity_max: number
  item: OrderBookItem
}
function OrderBookRow(props: Props) {
  const { item, type, quantity_max } = props
  const { price, quantity, quantity_total } = item

  const total = useTotal({ price, quantity })
  const fillPercent = (quantity_total / quantity_max) * 100

  const color = useMemo(() => {
    if (type === 'SELL') return 'rgb(207, 48, 74)'
    if (type === 'BUY') return 'rgb(3, 166, 109)'
    return '#323232'
  }, [type])

  const quantityFormatted = useMemo(() => _.round(quantity, 2), [quantity])

  const totalFormatted = useMemo(() => _.round(total), [total])

  return (
    <Grid
      container
      alignItems={'center'}
      justifyContent={'space-between'}
      css={css`
        padding: 3px 0;
        position: relative;
        .MuiTypography-root {
          font-size: 12px;
        }

        & > .MuiGrid-item {
          flex: 1 0 0;
        }
      `}
    >
      <div
        css={css`
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          opacity: 0.2;
        `}
        style={{
          backgroundColor: color,
          width: `${fillPercent}%`,
        }}
      />
      <Grid item flexGrow={2}>
        <Typography style={{ color }}>{price}</Typography>
      </Grid>

      <Grid item>
        <Typography textAlign={'right'}>{quantityFormatted}</Typography>
      </Grid>

      <Grid item>
        <Typography textAlign={'right'}>{totalFormatted}</Typography>
      </Grid>
    </Grid>
  )
}

export default OrderBookRow
