/** @jsx jsx */
import apiWalletClient from '@app/libs/api-wallet-client/api'
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { FadeLoader } from 'react-spinners'
// import { useAsyncFetch } from '../../../packages/react-hooks-async-handlers'
import DepositFormChainAddress from './DepositFormChainAddress'
import MockedInput from './components/MockedInput'

interface Props {
  chain: any
  onChainClick(): any
}

function DepositFormChain(props: Props) {
  const { chain, onChainClick } = props
  const { short_name, name, id } = chain

  const addressFetchAction = useAsyncFetch(async () => {
    return apiWalletClient.chain.getChainDepositAddress({ chain_id: id })
  }, [id])

  const handleClickSelectChain = () => {
    onChainClick()
  }

  return (
    <div
      css={css`
        margin-top: 10px;
      `}
    >
      <MockedInput name={name} short_name={short_name} onClick={handleClickSelectChain} />

      <div
        css={css`
          padding: 20px 10px 10px;
        `}
      >
        <RendererStatusSplit
          statuses={addressFetchAction}
          renderEmpty={() => (
            <div
              css={css`
                padding: 0 20px;
              `}
            >
              <Typography>Seems you have no address. Contact support if issue will continue happening.</Typography>
            </div>
          )}
          renderLoading={() => (
            <Grid container justifyContent={'center'}>
              <FadeLoader />
            </Grid>
          )}
          render={(data) => <DepositFormChainAddress chainAddress={data} />}
        />
      </div>
    </div>
  )
}

export default DepositFormChain
