/** @jsx jsx */
import CurrencyDispatcher from '@app/reducers/currencies/dispatcher'
import { Currency } from '@app/typings/core'
import { jsx, css } from '@emotion/react'
import { Grid } from '@mui/material'
import React, { useState } from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import Loader from '../../components/Loader'
import AppBar from '../../components/appBar/AppBar'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import WalletBreadcrumbs from './components/WalletBreadcrumbs'
import DepositFormCurrency from './deposit/DepositFormCurrency'
import DepositTransactionSubmit from './deposit/DepositTransactionSubmit'
import useSelectedCurrency from './hooks/useSelectedCurrency'

function WalletDeposit() {
  const dispatch = useDispatch<ReduxDispatch>()
  const fetchAction = useAsyncFetch(async () => {
    await dispatch(CurrencyDispatcher.getList())
  })

  const [itemSelected, setSelectedCurrencyId] = useSelectedCurrency()
  const [chain_id, setChainId] = useState<string | null>(null)

  const handleCurrencySelect = (currency: Currency) => {
    setSelectedCurrencyId(currency.id)
  }

  return (
    <div
      css={css`
        min-height: 700px;
      `}
    >
      <AppBar />

      <Grid container justifyContent={'center'}>
        <div
          css={css`
            max-width: 1000px;
            width: 100%;
            padding-top: 20px;
          `}
        >
          <WalletBreadcrumbs title={'Deposit'} />

          <div
            css={css`
              margin-top: 50px;
            `}
          >
            <Grid container justifyContent={'center'}>
              <Grid item md={6}>
                <RendererStatusSplit
                  statuses={fetchAction}
                  isEmpty={!itemSelected}
                  renderLoading={() => (
                    <Grid container justifyContent={'center'}>
                      <Loader />
                    </Grid>
                  )}
                  renderEmpty={() => <div>Not found</div>}
                  render={() => (
                    <DepositFormCurrency
                      currency={itemSelected}
                      onSelect={handleCurrencySelect}
                      onChainChange={setChainId}
                    />
                  )}
                />

                {/* <DepositTransactionSubmit chain_id={chain_id} /> */}
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
    </div>
  )
}

export default WalletDeposit
