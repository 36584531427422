/** @jsx jsx */
import AuthDispatcher from '@app/reducers/auth/dispatcher'
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import { ReduxState } from '@reducers/index'
import { QRCodeSVG } from 'qrcode.react'
import React, { useEffect } from 'react'
import { useAsyncFetch, useAsyncIntervalFetch } from 'react-hooks-async-handlers'
import { useSelector } from 'react-redux'
import RendererStatusSplit from 'react-renderer-status-split'
import Loader from '../../../components/Loader'
import { useAppDispatch } from '../../../libs/redux'

interface Props {
  onLoginSuccess(): any
}
function AuthScan(props: Props) {
  const { onLoginSuccess } = props

  const dispatch = useAppDispatch()
  const { token, remoteSessionId } = useSelector((state: ReduxState) => ({
    remoteSessionId: state.auth.remoteSessionId,
    token: state.auth.token,
  }))
  const fetchRemoteAuth = useAsyncFetch(async () => {
    await dispatch(AuthDispatcher.createRemoteLogin())
  }, [])

  const intervalFetch = useAsyncIntervalFetch(async () => {
    if (!remoteSessionId) return null
    await dispatch(AuthDispatcher.verifyRemoteSession())
  }, 5000)

  useEffect(() => {
    if (token) {
      onLoginSuccess()
    }
  }, [token])

  return (
    <Grid
      container
      direction={'column'}
      alignItems={'center'}
      css={css`
        padding-top: 50px;
      `}
    >
      <Typography
        variant={'h2'}
        css={css`
          margin-bottom: 20px;
        `}
      >
        Scan QR to login from mobile
      </Typography>
      <RendererStatusSplit
        statuses={fetchRemoteAuth}
        isEmpty={!remoteSessionId}
        renderLoading={() => (
          <Grid container justifyContent={'center'}>
            <Loader />
          </Grid>
        )}
        renderError={(error) => <Typography color={'error'}>{error}</Typography>}
        renderEmpty={() => <Typography>Couldn't create session.</Typography>}
        render={() => (
          <div>
            <QRCodeSVG value={remoteSessionId as string} size={150} />
          </div>
        )}
      />
    </Grid>
  )
}

export default AuthScan
