import { CombinedState, combineReducers, Reducer } from 'redux'
import app from './app/reducer'
import auth from './auth/reducer'
import chains from './chains/reducer'
import currencies from './currencies/reducer'
import currencyBucket from './currencyBucket/reducer'
import lists from './lists/reducer'
import markets from './markets/reducer'
import me from './me/reducer'
import orders from './orders/reducer'
import wallet from './wallet/reducer'

const reducers = {
  app,
  auth,
  me,
  lists,
  currencies,
  currencyBucket,
  markets,
  orders,
  wallet,
  chains,
}

const sharedReducer = combineReducers(reducers)
export type ReduxSharedState = ReturnType<typeof sharedReducer>

export default reducers
