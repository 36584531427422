/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { ChangeEventHandler } from 'react'
import { Currency } from '@app/typings/core'

interface Props {
  id: string
  value: string
  onChange: ChangeEventHandler<HTMLInputElement>
  title: string
  currency?: Currency
}

function FormInput(props: Props) {
  const { id, onChange, value, title, currency } = props
  const { name, symbol } = currency || {}

  return (
    <div
      css={css`
        margin: 16px 0px 8px;
        min-width: 0px;
        width: 100%;
        box-sizing: border-box;
        border-radius: 4px;
        height: 40px;
        line-height: 40px;
        background-color: rgba(230, 232, 234, 0.6);
      `}
    >
      <div
        css={css`
          box-sizing: border-box;
          margin: 0px;
          min-width: 0px;
          display: inline-flex;
          position: relative;
          -webkit-box-align: center;
          align-items: center;
          line-height: 1.6;
          border: 1px solid rgba(230, 232, 234, 0.1);
          border-radius: 4px;
          width: 100%;
          height: 100%;
          font-size: 14px;
        `}
      >
        <div
          css={css`
            flex-shrink: 0;
            margin-left: 8px;
            min-width: 48px;
            font-size: 14px;
            color: rgb(71, 77, 87);
          `}
        >
          <label
            htmlFor={id}
            css={css`
              box-sizing: border-box;
              margin: 0px;
              min-width: 0px;
              font-size: 14px;
              color: rgb(132, 142, 156);
              display: inline-block;
              text-align: right;
            `}
          >
            {title}
          </label>
        </div>

        <input
          type="text"
          id={id}
          value={value}
          onChange={onChange}
          autoComplete={'off'}
          autoCorrect={'off'}
          css={[
            css`
              box-sizing: border-box;
              margin: 0px;
              min-width: 0px;
              width: 100%;
              height: 100%;
              padding: 0px;
              outline: none;
              background-color: inherit;
              opacity: 1;
              border: none !important;
            `,
            css`
              color: rgb(30, 35, 41);
              font-size: 14px;
              padding-left: 4px;
              padding-right: 4px;
              text-align: right;
            `,
          ]}
        />

        <div
          css={css`
            flex-shrink: 0;
            margin-right: 8px;
            font-size: 14px;
            color: rgb(112, 122, 138);
          `}
        >
          <label
            htmlFor={id}
            css={css`
              box-sizing: border-box;
              margin: 0px;
              min-width: 0px;
              font-size: 14px;
              color: rgb(30, 35, 41);
              display: inline-block;
              text-align: right;
            `}
          >
            {symbol}
          </label>
        </div>
      </div>
    </div>
  )
}

export default FormInput
