import _ from 'lodash'
import React, { ReactElement, ReactNode } from 'react'

interface Props<DataType> {
  statuses: {
    isLoading?: boolean
    isDone?: boolean
    error?: string
  }
  renderError?: (error: string) => ReactNode
  renderLoading?: () => ReactNode
  renderSuccess?: () => ReactNode
  render: () => ReactNode
}

function RendererActionSplit<DataType = any>(props: Props<DataType>): ReactElement | null {
  const { statuses, render, renderError, renderLoading, renderSuccess } = props
  const { isLoading, isDone, error } = statuses

  if (isLoading && renderLoading) {
    return <>{renderLoading()}</>
  }

  if (error && renderError) {
    return <>{renderError(error)}</>
  }

  if (isDone) {
    if (_.isFunction(renderSuccess)) return <>{renderSuccess()}</>
    return null
  }

  return <>{render()}</>
}

export default RendererActionSplit
