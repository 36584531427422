import api from '@app/reducers/me/api'
import { DispatcherResponse } from '../../typings/ReduxDispatch'
import { actions } from './reducer'

class Dispatch {
  getMe(): DispatcherResponse<void | null> {
    return async (dispatch) => {
      const user = await api.getMe()

      if (!user) return null

      dispatch(actions.set(user))
    }
  }
}

const MeDispatcher = new Dispatch()
export default MeDispatcher
