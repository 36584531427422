/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Table, TableBody, TableContainer } from '@mui/material'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import BootstrapInput from '../../../elements/form/BootstrapInput'
import WalletAssetsTable from './components/WalletAssetsTable'
import WalletTableRow from './components/WalletTableRow'

interface Props {}
function UserAssetsTable(props: Props) {
  const [search, setSearch] = useState('')

  const { list } = useSelector((state: ReduxState) => ({
    list: state.lists.walletList,
  }))

  const listFiltered = useMemo(() => {
    if (!search) return list
    const searchLow = search.toLowerCase()
    return list.filter(
      (item) => item.name.toLowerCase().includes(searchLow) || item.symbol.toLowerCase().includes(searchLow),
    )
  }, [search, list])

  // todo when we will have more currencies, add api call search

  return (
    <div>
      <BootstrapInput
        value={search}
        placeholder={'Search'}
        onChange={(e) => setSearch(e.target.value || '')}
        css={css`
          min-width: 300px;
          margin-bottom: 10px;
        `}
      />

      <TableContainer>
        <Table size={'small'}>
          <WalletAssetsTable />
          <TableBody>
            {_.map(listFiltered, (asset) => (
              <WalletTableRow asset={asset} key={`asset-item-${asset.id}`} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default UserAssetsTable
