/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { faReceipt, faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AuthDispatcher from '@app/reducers/auth/dispatcher'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import ProfileTab from './ProfileTab'

interface Props {}

const cssIcon = css`
  font-size: 20px;
  color: #767b97;
`

function ProfileTabs(props: Props) {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch<ReduxDispatch>()
  const { pathname } = location

  const handleClickLogout = () => {
    navigate('/', { replace: true })
    dispatch(AuthDispatcher.logout())
  }

  return (
    <div>
      <div>
        <Link to={'/wallet'}>
          <ProfileTab isSelected={pathname === '/wallet'} icon={<FontAwesomeIcon icon={faReceipt} css={cssIcon} />}>
            Overview
          </ProfileTab>
        </Link>

        <ProfileTab icon={<FontAwesomeIcon icon={faRightFromBracket} css={cssIcon} />} onClick={handleClickLogout}>
          Logout
        </ProfileTab>
      </div>
    </div>
  )
}

export default ProfileTabs
