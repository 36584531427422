/** @jsx jsx */
import MarketDispatcher from '@app/reducers/markets/dispatcher'
import { MarketFull } from '@app/typings/core'
import { jsx, css } from '@emotion/react'
import { ReduxState } from '@reducers/index'
import React, { useState } from 'react'
import { useAsyncIntervalFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import useIsLoggedIn from '../../../hooks/useIsLoggedIn'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import { OrderCreateContext } from './orderCreate/OrderCreateContext'
import PriceTypeToggle from './orderCreate/PriceTypeToggle'
import TradeOrderAvailableEmount from './orderCreate/TradeOrderAvailableEmount'
import TradeOrderForm from './orderCreate/TradeOrderForm'
import TradeOrderLoginLinks from './orderCreate/TradeOrderLoginLinks'
import TradeOrderTypeToggle from './orderCreate/TradeOrderTypeToggle'

interface Props {
  market: MarketFull
}

function TradeOrderCreate(props: Props) {
  const { market } = props
  const [type, setType] = useState<'BUY' | 'SELL'>('BUY')
  const [priceType, setPriceType] = useState<'MARKET' | 'LIMIT'>('LIMIT')
  const isLoggedIn = useIsLoggedIn()
  const { currency_id, currency_secondary_id } = market

  const { walletMain, walletSecondary } = useSelector((state: ReduxState) => ({
    walletMain: state.wallet[currency_id],
    walletSecondary: state.wallet[currency_secondary_id],
  }))

  const dispatch = useDispatch<ReduxDispatch>()

  useAsyncIntervalFetch(async () => {
    if (document.hidden || !isLoggedIn) return null
    await dispatch(MarketDispatcher.onIntervalRefresh(market.id))
  }, 30000)

  return (
    <OrderCreateContext.Provider value={{ market, walletMain, walletSecondary }}>
      <div
        css={css`
          padding: 10px;
          max-width: 300px;
        `}
      >
        <PriceTypeToggle type={priceType} onChange={setPriceType} />

        <TradeOrderTypeToggle type={type} onChange={setType} />

        {isLoggedIn ? <TradeOrderAvailableEmount type={type} /> : null}

        <TradeOrderForm type={type} price_type={priceType} market={market} isButtonsVisible={isLoggedIn} />

        {isLoggedIn ? null : <TradeOrderLoginLinks />}
      </div>
    </OrderCreateContext.Provider>
  )
}

export default TradeOrderCreate
