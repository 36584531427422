/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import useWalletQuantityAvailable from '../../../../hooks/wallet/useWalletQuantityAvailable'
import { WalletCurrency } from '@app/typings/core'
import CoinInfoCompact from './components/CoinInfoCompact'
import CurlyNumber from './components/CurlyNumber'

const cssLink = css`
  color: rgb(201, 148, 0);
  margin-right: 10px;

  &:hover {
    color: rgb(240, 185, 11);
  }
`

interface Props {
  asset: WalletCurrency
}

function WalletTableRow(props: Props) {
  const { asset } = props
  const { id, quantity, quantity_frozen = 0 } = asset

  const quantity_available = useWalletQuantityAvailable(asset)

  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      css={css`
        font-size: 12px;
      `}
    >
      <TableCell component="th" scope="row">
        <CoinInfoCompact currency={asset} />
      </TableCell>
      <TableCell>
        <CurlyNumber value={quantity} />
      </TableCell>
      <TableCell>
        <CurlyNumber value={quantity_available} />
      </TableCell>
      <TableCell>
        <CurlyNumber value={quantity_frozen} />
      </TableCell>
      <TableCell>
        <span>-</span>
      </TableCell>
      <TableCell>
        {/* <LinkTrade>Trade</LinkTrade> */}

        <Link to={`/wallet/deposit?currency=${id}`} css={cssLink}>
          Deposit
        </Link>
        <Link to={`/wallet/withdraw?currency=${id}`} css={cssLink}>
          Withdraw
        </Link>
      </TableCell>
    </TableRow>
  )
}

export default WalletTableRow
