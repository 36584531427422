/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { ReactElement } from 'react'

interface Props {
  children: ReactElement
}
function HomePreviewContainer(props: Props) {
  const { children } = props

  return (
    <div
      css={css`
        padding: 10px 10px;
        border: 1px solid #fff;
        border-radius: 6px;
        margin-right: 20px;
        cursor: pointer;

        .MuiTypography-root {
          color: #fff;
        }
      `}
    >
      {children}
    </div>
  )
}

export default HomePreviewContainer
