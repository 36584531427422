import React, { useMemo } from 'react'
import useWalletQuantityAvailable from '../../../../hooks/wallet/useWalletQuantityAvailable'
import { useOrderCreateMarketContext } from './OrderCreateContext'
import Info from './elements/Info'

interface Props {
  type: 'BUY' | 'SELL'
}

function TradeOrderAvailableEmount(props: Props) {
  const { type } = props

  const { market, walletMain, walletSecondary } = useOrderCreateMarketContext()
  const { currency, currency_secondary } = market

  const mainWalletAvailable = useWalletQuantityAvailable(walletMain)
  const secondWalletAvailable = useWalletQuantityAvailable(walletSecondary)

  const valueFormatted = useMemo(() => {
    let value: string | number = 0
    let label = ''
    if (type === 'BUY') {
      label = currency_secondary?.symbol
      if (walletSecondary) {
        value = secondWalletAvailable
        label = walletSecondary.symbol
      }
    } else if (type === 'SELL') {
      label = currency?.symbol
      if (walletMain) {
        value = mainWalletAvailable
        label = walletMain.symbol
      }
    }

    return `${value} ${label || ''}`
  }, [type, walletMain, walletSecondary])

  return <Info title={'Available:'} value={valueFormatted || '-'} />
}

export default TradeOrderAvailableEmount
