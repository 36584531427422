/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { TableCell, TableRow } from '@mui/material'
import Big from 'big.js'
import _ from 'lodash'
import moment from 'moment'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Order } from '@app/typings/core'
import OrderListItemStatus from './OrderListItemStatus'

interface Props {
  order: Order
}

const SpanStyled = styled.span`
  font-size: 12px;
`

function OrderListItem(props: Props) {
  const { order } = props
  const {
    order_type,
    price,
    price_type,
    quantity,
    quantity_secondary,
    quantity_secondary_filled,
    quantity_filled = 0,
    market_key,
    status,
    created_at,
  } = order

  const dateFormatted = moment(created_at).format('MM-DD HH:mm:ss')

  const quantityFormatted = useMemo(() => {
    return quantity || quantity_filled
  }, [quantity, quantity_filled])

  const totalSecondary = useMemo(() => {
    if (quantity_secondary_filled) return quantity_secondary_filled
    // if (!price) return null
    // const priceBn = new Big(price)
    // const quantityBn = new Big(quantity)
    // const totalBN = priceBn.mul(quantityBn)
    // return totalBN.toString()
    return ''
  }, [price, quantity, quantity_secondary])

  const filledPercent = useMemo(() => {
    let value = 0
    if (quantity) {
      value = (quantity_filled / quantity) * 100
    }
    if (quantity_secondary) {
      value = (quantity_secondary_filled / quantity_secondary) * 100
    }

    if (value) {
      return _.round(value, 1)
    }

    return ''
  }, [quantity_filled, quantity])

  const priceTxt = price_type === 'MARKET' ? '-' : price
  const priceTypeFormatted = useMemo(() => {
    if (price_type === 'LIMIT') return 'Limit'
    if (price_type === 'MARKET') return 'Market'
    return 'Unknown'
  }, [price_type])

  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      css={css`
        font-size: 12px;
      `}
    >
      <TableCell component="th" scope="row">
        <SpanStyled>{dateFormatted}</SpanStyled>
      </TableCell>
      <TableCell>
        <Link
          to={`/trade/${market_key}`}
          css={css`
            &:hover {
              color: #f89852;
            }
          `}
        >
          <SpanStyled>{market_key}</SpanStyled>
        </Link>
      </TableCell>
      <TableCell>
        <SpanStyled>{priceTypeFormatted}</SpanStyled>
      </TableCell>
      <TableCell>
        <SpanStyled>
          {order_type === 'SELL' && (
            <span
              css={css`
                color: rgb(207, 48, 74);
              `}
            >
              Sell
            </span>
          )}
          {order_type === 'BUY' && (
            <span
              css={css`
                color: rgb(3, 166, 109);
              `}
            >
              Buy
            </span>
          )}
        </SpanStyled>
      </TableCell>
      <TableCell>
        <SpanStyled>{priceTxt}</SpanStyled>
      </TableCell>
      <TableCell>
        <SpanStyled>{quantityFormatted}</SpanStyled>
      </TableCell>
      <TableCell>
        <SpanStyled>{filledPercent ? `${filledPercent}%` : '-'}</SpanStyled>
      </TableCell>
      <TableCell>
        <SpanStyled>{totalSecondary}</SpanStyled>
      </TableCell>
      <TableCell>
        <SpanStyled>-</SpanStyled>
      </TableCell>
      <TableCell align="right">
        <OrderListItemStatus order={order} />
      </TableCell>
    </TableRow>
  )
}

export default OrderListItem
