/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React, { useState } from 'react'
import Loader from '../../../../components/Loader'

const useStyles = makeStyles(
  {
    buttonContainer: {
      marginBottom: 10,
    },
    button: {
      border: '1px solid #DEDEDE',
      borderRadius: 10,
      width: '100%',
      height: 42,
      backgroundColor: '#fff',
      outline: 'none',
      userSelect: 'none',
      minWidth: 200,
    },

    buttonText: {
      color: '#1A1A1A',
      fontSize: 12,
    },

    buttonInteractions: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#f7f8ff',
      },
    },
  },
  {
    classNamePrefix: 'LoginButton',
  },
)

interface Props {
  icon: string
  title: string
  onLogin(): Promise<any>
}

function LoginButton(props: Props) {
  const { icon, title, onLogin } = props
  const classes = useStyles()
  const [isLoading, setLoading] = useState(false)

  const handleClick = () => {
    setLoading(true)

    onLogin().finally(() => {
      setLoading(false)
    })
  }

  return (
    <div className={classes.buttonContainer}>
      <button
        type={'button'}
        onClick={handleClick}
        className={classNames(classes.button, {
          [classes.buttonInteractions]: !isLoading,
        })}
        disabled={isLoading}
      >
        {isLoading ? (
          <Grid container alignItems={'center'} justifyContent={'center'}>
            <Loader
              scale={0.3}
              css={css`
                margin-top: -10px;
              `}
            />
          </Grid>
        ) : (
          <Grid container direction={'row'} alignItems={'center'}>
            <div
              css={css`
                width: 50px;
                padding-right: 10px;
              `}
            >
              <img
                src={icon}
                alt="google"
                css={css`
                  width: 22px;
                  height: 22px;
                  object-fit: contain;
                `}
              />
            </div>
            <Typography
              css={css`
                color: #1a1a1a;
                font-size: 12px;
                text-align: center;
                flex-grow: 1;
                padding-right: 50px;
              `}
            >
              {title}
            </Typography>
          </Grid>
        )}
      </button>
    </div>
  )
}

export default LoginButton
