import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import moment from 'moment'
import { ImmutableDate } from 'seamless-immutable'
import { DispatcherResponseCreate } from '../typings/ReduxDispatch'

export const TTL = {
  MINUTE: 60 * 1000,
  TEN_MINUTES: 60 * 10 * 1000,
  HOUR: 60 * 60 * 1000,
  TEN_HOURS: 10 * 60 * 60 * 1000,
  DAY: 24 * 60 * 60 * 1000,
  TWO_DAYS: 48 * 60 * 60 * 1000,
  WEEK: 7 * 24 * 60 * 60 * 1000,
}

type Data =
  | { storeUpdatedAt: StoreUpdatedAt | ImmutableDate }
  | Date
  | StoreUpdatedAt
  | null
  | undefined
  | ImmutableDate
function shouldUpdate(data: Data, cacheTTL: number) {
  if (!data) return true

  let updateTime
  if (_.isNumber(data) || _.isDate(data)) {
    updateTime = moment(data)
  } else {
    if (!data.storeUpdatedAt) return true
    updateTime = moment(data.storeUpdatedAt)
  }

  const diff = moment.now() - updateTime

  return diff > cacheTTL
}

export function createLazy<State extends {} = ReduxState, Response = any>(options: {
  isForce?: boolean
  onFindStoreUpdatedAt(state: State): StoreUpdatedAt | { storeUpdatedAt: StoreUpdatedAt }
  onExecute: DispatcherResponseCreate<State, Response>
  timeout: number
}): DispatcherResponseCreate<State, Response | null> {
  let { isForce, onExecute, onFindStoreUpdatedAt, timeout } = options

  return async function lazyDispatchFn(dispatch, getState) {
    if (!isForce) {
      const updateInfo = onFindStoreUpdatedAt(getState())
      const isShouldUpdate = shouldUpdate(updateInfo, timeout)
      if (!isShouldUpdate) return null
    }

    const response = await onExecute(dispatch, getState)

    return response
  }
}

export default {
  TTL,
  createLazy,
  shouldUpdate,
}
