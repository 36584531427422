import { Tab, Box, Tabs } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import TradeOrderListActive from './TradeOrderListActive'
import TradeOrderListHistory from './TradeOrderListHistory'

function TradeOrderList() {
  const dispatch = useDispatch<ReduxDispatch>()
  const [activeTab, setActiveTab] = useState(0)

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={(e, index) => setActiveTab(index)} aria-label="basic tabs example">
          <Tab label="Open Orders" />
          <Tab label="Order History" />
        </Tabs>
      </Box>

      <div>
        {activeTab === 0 && <TradeOrderListActive />}
        {activeTab === 1 && <TradeOrderListHistory />}
      </div>
    </div>
  )
}

export default TradeOrderList
