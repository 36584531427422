import React from 'react'
import FooterContacts from './home/FooterContacts'
import HomeChains from './home/HomeChains'
import HomeHead from './home/HomeHead'

function Home() {
  return (
    <div>
      <HomeHead />
      <HomeChains />
      <FooterContacts />
    </div>
  )
}

export default Home
