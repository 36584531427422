import { ReduxStateMarketsItem } from '@app/reducers/markets/reducer'
import _ from 'lodash'
import { useMemo } from 'react'

export interface OrderBookItem {
  price: number
  quantity: number
  quantity_total: number
}

/**
 *
 * @returns:
 *  @param quantity_max - represent max value across all inputs. It allow to compare sell and buy between them
 */

interface Props {
  market: ReduxStateMarketsItem
  length: number
}

function useOrderBookArrays(props: Props) {
  const { market, length } = props
  const { price, orderBook } = market
  const { book_up = [], book_down = [], group_key } = orderBook || {}

  // todo implement auto step size
  const stepSize = Number(group_key) || 1

  const { list: listDown, quantity_total: listDownQTotal } = useMemo(() => {
    const result = getList({
      list: book_down,
      stepSize,
      price,
      sort: 'desc',
      length,
      calculateNextStep({ step, stepCurrent }) {
        return stepCurrent + step
      },
    })

    return result
  }, [book_down, stepSize])

  // LIST UP
  const { list: listUp, quantity_total: lostUpQTotal } = useMemo(() => {
    const result = getList({
      list: book_up,
      stepSize,
      price,
      sort: 'asc',
      length,
      calculateNextStep({ step, stepCurrent }) {
        return stepCurrent + step
      },
    })

    return result
  }, [book_up, stepSize])

  let quantity_max = listDownQTotal
  if (quantity_max < lostUpQTotal) quantity_max = lostUpQTotal

  return {
    listUp,
    listDown,
    quantity_max,
  }
}

export default useOrderBookArrays

function getList({
  list,
  stepSize,
  price,
  sort,
  length = 8,
}: {
  sort: 'asc' | 'desc'
  list: OrderBookItem[]
  stepSize: number
  price: number | string
  length?: number
}) {
  let quantity_total = 0

  let arr = _.clone(list)
  arr = _.orderBy(arr, (item) => item.price, sort)

  let priceStr = arr[0]?.price
  if (!priceStr) {
    // price must be rounded to step start
    let priceN = Number(price)
    let diff = priceN % stepSize
    priceN = priceN - diff + stepSize
    priceStr = priceN
  }

  const listNextArr: OrderBookItem[] = []
  let stepCurrent = Number(priceStr)
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i]
    const itemPrice = Number(item.price)
    while (stepCurrent < itemPrice) {
      if (listNextArr.length >= length) break
      listNextArr.push({
        price: stepCurrent,
        quantity: 0,
        quantity_total,
      })
      stepCurrent = calculateNextStep()
      if (stepCurrent < 0) break
    }

    if (listNextArr.length >= length) break
    quantity_total += Number(item.quantity)

    listNextArr.push({
      ...item,
      quantity_total,
    })

    stepCurrent = calculateNextStep()
    if (stepCurrent < 0) break
  }

  while (listNextArr.length < length) {
    stepCurrent = calculateNextStep()
    if (stepCurrent < 0) break
    listNextArr.push({ price: stepCurrent, quantity: 0, quantity_total })
  }

  return { list: listNextArr, quantity_total }

  // ** helpers ** /
  function calculateNextStep() {
    if (sort === 'asc') {
      stepCurrent += stepSize
    } else if (sort === 'desc') {
      stepCurrent -= stepSize
    } else throw new Error('invalid steps')

    return stepCurrent
  }
}
