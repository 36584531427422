/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import LoginCredentialsForm from './components/LoginCredentialsForm'

interface Props {
  onLoginSuccess(): any
}

function LoginForms(props: Props) {
  const { onLoginSuccess } = props

  return (
    <Grid container direction={'column'} justifyContent={'center'}>
      <Grid item sm={6}>
        <LoginCredentialsForm onLoginSuccess={onLoginSuccess} />

        {/* <FormControl className={classes.buttonContainer} error={loginError}> */}
        {/*  <button */}
        {/*    type={'button'} */}
        {/*    onClick={this.handleSubmit} */}
        {/*    className={classNames(classes.button, { */}
        {/*      [classes.buttonInteractions]: !isLoading, */}
        {/*    })} */}
        {/*    disabled={isLoading} */}
        {/*  > */}
        {/*    {isLoading ? ( */}
        {/*      <Grid container alignItems={'center'} justifyContent={'center'}> */}
        {/*        <Loader /> */}
        {/*      </Grid> */}
        {/*    ) : ( */}
        {/*      <Grid container direction={'row'} alignItems={'center'}> */}
        {/*        <span className={classes.buttonText}>Login</span> */}
        {/*      </Grid> */}
        {/*    )} */}
        {/*  </button> */}
        {/*  {loginError && <FormHelperText className={classes.loginErrorText}>Failed to sign in</FormHelperText>} */}
        {/* </FormControl> */}
        <Grid container justifyContent={'center'}>
          <Link
            css={css({
              color: '#0a58ba',
              fontSize: 12,
              marginBottom: 10,
              textDecoration: 'none',
            })}
            to={`/signup${location.search}`}
          >
            Create an account
          </Link>
        </Grid>

        {/*<Link*/}
        {/*  css={css({*/}
        {/*    color: '#1A1A1A',*/}
        {/*    fontSize: 12,*/}
        {/*    width: '70%',*/}
        {/*    minWidth: 200,*/}
        {/*    marginLeft: '15%',*/}
        {/*    marginBottom: 10,*/}
        {/*  })}*/}
        {/*  to={'/reset-password'}*/}
        {/*>*/}
        {/*  I forgot my password.*/}
        {/*</Link>*/}
      </Grid>
    </Grid>
  )
}

export default LoginForms
