/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid } from '@mui/material'
import React from 'react'
import { CellContainer } from './styled'

function MarketsTableHead() {
  return (
    <Grid
      container
      css={css`
        background-color: #ececec;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      `}
    >
      <Grid item>
        <CellContainer
          css={css`
            width: 400px;
          `}
        >
          <span>Name</span>
        </CellContainer>
      </Grid>

      <Grid item>
        <CellContainer>
          <span>Price</span>
        </CellContainer>
      </Grid>

      <Grid item>
        <CellContainer>
          <span>24h Change</span>
        </CellContainer>
      </Grid>

      {/*<Grid item>*/}
      {/*  <CellContainer>*/}
      {/*    <span>24h Volume</span>*/}
      {/*  </CellContainer>*/}
      {/*</Grid>*/}

      <Grid item> </Grid>
    </Grid>
  )
}

export default MarketsTableHead
