/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import bscIcon from '../assets/chains/bsc.svg'
import ethIcon from '../assets/chains/eth.svg'
import polygonIcon from '../assets/chains/polygon.svg'
import HomeChainItem from './components/HomeChainItem'

function HomeChains() {
  return (
    <div
      css={css`
        //background-color: #0b1928;
        background-color: #fff;
        padding: 20px;
      `}
    >
      <Typography
        variant={'h2'}
        css={css`
          //color: white;
          text-align: center;
        `}
      >
        Supported Chains
      </Typography>
      <div>
        <Grid container justifyContent={'center'} alignItems={'center'}>
          <HomeChainItem
            title={'Ethereum'}
            iconUrl={ethIcon}
            size={'small'}
            css={css`
              margin-right: 10px;
            `}
          />
          <HomeChainItem title={'Binance Smart Chain'} iconUrl={bscIcon} />
          <HomeChainItem title={'Polygon'} iconUrl={polygonIcon} />
        </Grid>
      </div>

      <Typography
        css={css`
          font-style: italic;
          text-align: center;
        `}
      >
        Coming more soon...
      </Typography>
    </div>
  )
}

export default HomeChains
