/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import IFrameGuard from 'react-iframe-guard'
import { useNavigate } from 'react-router-dom'
import AppBar from '../../components/appBar/AppBar'
import LocalSignUp from './signup/LocalSignUp'

function SignupPage(props: {}) {
  const navigate = useNavigate()

  const handleLoginSuccess = () => {
    navigate('/signup-wait-for-user')
  }

  return (
    <IFrameGuard>
      <div>
        <AppBar />

        <Grid container justifyContent={'center'}>
          <Grid item md={8}>
            <Grid container>
              <Grid item md={4}>
                <Typography
                  variant={'h1'}
                  css={css`
                    margin-top: 20px;
                    margin-bottom: 20px;
                  `}
                >
                  Create a new email account
                </Typography>

                <LocalSignUp onLoginSuccess={handleLoginSuccess} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </IFrameGuard>
  )

  // async componentDidMount() {
  //   const { history, loginForAdmin, token } = this.props
  //   const {
  //     location: { search },
  //   } = history
  //
  //   const adminToken = new URLSearchParams(search).get('token')
  //
  //   const login = new URLSearchParams(search).get('login')
  //
  //   if (login) {
  //     this.setState({
  //       login: login.split(' ').join('+'),
  //     })
  //   }
  //
  //   this.setState({
  //     type: new URLSearchParams(search).get('type'),
  //   })
  //
  //   // const adminChatToken = new URLSearchParams(search).get('chatToken')
  //   // const adminChatUserId = new URLSearchParams(search).get('chatUserId')
  //
  //   if (adminToken) {
  //     await loginForAdmin({
  //       token: adminToken,
  //       // chatToken: adminChatToken,
  //       // chatUserId: adminChatUserId,
  //     })
  //
  //     return history.push(GROUP_LIST_URL)
  //   }
  //
  //   if (token) {
  //     return history.push(GROUP_LIST_URL)
  //   }
  // }
}

export default SignupPage
