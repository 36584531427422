/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { faTwitter, faTelegram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import config from '../../../config'

function FooterContacts() {
  return (
    <div
      css={css`
        padding: 20px;
        background: #1e0f47;

        .MuiTypography-root,
        svg {
          color: #fff;
        }

        .icon {
          margin-right: 10px;
        }

        a {
          display: inline-block;
          margin-top: 10px;
        }
      `}
    >
      <Grid container flexDirection={'column'} alignItems={'center'}>
        <div>
          <Typography
            variant={'h2'}
            textAlign={'center'}
            css={css`
              margin-bottom: 10px;
            `}
          >
            Contacts
          </Typography>
        </div>

        <a href={`https://twitter.com/${config.contacts.twitter.replace('@', '')}`} target={'_blank'} rel="noreferrer">
          <Grid container alignItems={'center'}>
            <div className={'icon'}>
              <FontAwesomeIcon icon={faTwitter} />
            </div>
            <Typography>{config.contacts.twitter}</Typography>
          </Grid>
        </a>

        <a href={config.contacts.telegram_announce} target={'_blank'} rel="noreferrer">
          <Grid container alignItems={'center'}>
            <div className={'icon'}>
              <FontAwesomeIcon icon={faTelegram} />
            </div>
            <Typography>TG Announcement</Typography>
          </Grid>
        </a>

        <a href={config.contacts.telegram_chat} target={'_blank'} rel="noreferrer">
          <Grid container alignItems={'center'}>
            <div className={'icon'}>
              <FontAwesomeIcon icon={faTelegram} />
            </div>
            <Typography>TG Chat</Typography>
          </Grid>
        </a>

        <a href={`mailto:${config.contacts.email}`} target={'_blank'} rel="noreferrer">
          <Grid container alignItems={'center'}>
            <div className={'icon'}>
              <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <Typography>{config.contacts.email}</Typography>
          </Grid>
        </a>

        <Link to={'/token'}>
          <Typography>Token info</Typography>
        </Link>
      </Grid>
    </div>
  )
}

export default FooterContacts
