import { io } from 'socket.io-client'
import config from '../../config'

const prefix = 'SOCKET:'

const socket = io(config.socket_url, {
  query: {
    // user_id: 'asd',
  },
  autoConnect: true,
  transports: ['websocket'],
})
console.log('io', config.socket_url)
// will create two distinct connections

socket.on('error', (error) => {
  // ...
  console.log(prefix, 'error', error)
})

socket.on('connect', () => {
  console.log(prefix, 'socket is opened')
})
socket.on('event', (data) => {
  console.log(prefix, 'message: ', data)
})
socket.on('test', (data) => {
  console.log(prefix, 'test: ', data)
})
socket.on('disconnect', () => {
  console.log(prefix, 'socket is closed ')
})
socket.on('connect_error', (e) => {
  console.log(prefix, 'connect_error ', e)
})
socket.on('reconnect_attempt', () => {
  console.log(prefix, 'reconnect_attempt ')
  // socket.io.opts.query = {
  //   token: 'fgh'
  // }
})

export default socket
