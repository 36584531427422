import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import invariant from 'invariant'
import appTypes from '@app/reducers/app/types'
import { Currency } from '../../typings/core'
import { ReduxStateWallet } from '../wallet/reducer'

interface State {
  [key: string]: ReduxStateCurrencyItem
}

export interface ReduxStateCurrencyItem extends Currency {
  chains?: any[]
}

const initialState: State = {}

const reducerSlice = createSlice({
  name: 'CurrencyBucket',
  initialState,
  extraReducers: (builder) => builder.addCase(appTypes.RESET, () => initialState),
  reducers: {
    setItem(state, action: PayloadAction<Currency>) {
      const item: Currency = action.payload
      state[item.id] = item
      // if (stateItem) {
      //   Object.assign(stateItem, market)
      // } else {
      //   state[market.key] = {
      //     ...market,
      //     orders: [],
      //   }
      // }
    },

    setChains(state, action: PayloadAction<{ id: string; list: ReduxStateCurrencyItem['chains'] }>) {
      const { id, list } = action.payload

      let stateItem = state[id]
      invariant(stateItem, 'currency is not found')
      stateItem.chains = list
    },
  },
})

export const { actions, reducer } = reducerSlice
export default reducer
