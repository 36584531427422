/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import React, { useCallback } from 'react'

interface Props {
  value: 'MARKET' | 'LIMIT'
  title: string
  isSelected: boolean
  onChange(type: 'MARKET' | 'LIMIT'): any
}

function PriceTypeItem(props: Props) {
  const { value, title, onChange, isSelected } = props

  const handleClick = useCallback(() => onChange(value), [value])

  return (
    <div
      onClick={handleClick}
      css={css`
        margin-right: 15px;
        cursor: pointer;
        user-select: none;
      `}
    >
      <Typography
        css={[
          css`
            font-weight: bold;
            font-size: 14px;
          `,
          !isSelected &&
            css`
              color: #aaa;
            `,
        ]}
      >
        {title}
      </Typography>
    </div>
  )
}

export default PriceTypeItem
