import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import invariant from 'invariant'
import _ from 'lodash'
import { Market, MarketCandle, MarketCandleInterval, MarketOrderBook, Order } from '../../typings/core'

interface State {
  [key: string]: ReduxStateMarketsItem
}

export interface ReduxStateMarketsItem extends Market {
  orders: Order[]
  orderBook?: MarketOrderBook
  candleActive?: MarketCandle
  candles?: MarketCandle[]
  candlesInterval?: MarketCandleInterval
  candlesUpdatedAt?: StoreUpdatedAt
  isCandlesDisabled?: boolean
}

const initialState: State = {}

const reducerSlice = createSlice({
  name: 'MARKET',
  initialState,
  reducers: {
    set(state, action: PayloadAction<Market>) {
      const market = action.payload
      const stateItem = state[market.key]
      if (stateItem) {
        Object.assign(stateItem, market)
      } else {
        state[market.key] = {
          ...market,
          orders: [],
        }
      }
    },
    setMarketPrice(state, action: PayloadAction<{ market_id: string; price: string }>) {
      const { market_id, price } = action.payload
      const market = _.find(state, { id: market_id })
      if (!market) {
        console.log('setMarketPrice:', 'market is not found')
        return
      }

      market.price = price
    },
    setOrders(state, action: PayloadAction<{ market_id: string; orders: Order[] }>) {
      const { market_id, orders = [] } = action.payload
      const key = _.findKey(state, (item) => item.id === market_id)
      invariant(key, 'market key is not found')
      state[key].orders = orders
    },
    setOrderBook(state, action: PayloadAction<{ market_id: string; data: any }>) {
      const { market_id, data = [] } = action.payload
      const key = _.findKey(state, (item) => item.id === market_id)
      invariant(key, 'market key is not found')
      state[key].orderBook = data
    },
    setCandles(
      state,
      action: PayloadAction<{ market_key: string; candles: MarketCandle[]; interval: MarketCandleInterval }>,
    ) {
      const { market_key, candles = [], interval } = action.payload
      const market = state[market_key]
      invariant(market, 'market is not found')
      market.candleActive = candles[candles.length - 1]
      market.candles = candles
      market.candlesInterval = interval
      market.candlesUpdatedAt = Date.now()
      market.isCandlesDisabled = false
    },

    setActiveCandle(state, action: PayloadAction<{ candle: MarketCandle }>) {
      const { candle } = action.payload
      const { market_id } = candle

      const market = _.find(state, (item) => item.id === market_id)
      invariant(market, 'market is not found')
      const { candles = [] } = market
      console.log('state', _.cloneDeep(state))

      const marketCandleLast = candles[candles.length - 1]

      if (marketCandleLast.time === candle.time) {
        // no need to replace prev candle. Interval can be different, so it will affect logic
        marketCandleLast.close = candle.close
        if (marketCandleLast.low > candle.low) marketCandleLast.low = candle.low
        if (marketCandleLast.high > candle.high) marketCandleLast.low = candle.high
      } else {
        const candlesNext = [...candles, candle]
        console.log('replace market candles with a new one')
        market.candles = candlesNext
      }

      market.candleActive = candle
    },

    setPreviousCandles(state, action: PayloadAction<{ market_key: string; candles: MarketCandle[] }>) {
      const { market_key, candles = [] } = action.payload
      const market = state[market_key]
      invariant(market, 'market is not found')

      let candlesNext = candles.concat(market.candles || [])
      candlesNext = _.uniqBy(candlesNext, (item) => item.time)
      market.candles = candlesNext

      if (candles.length < 10) {
        market.isCandlesDisabled = true
      }
    },
  },
})

export const { actions, reducer } = reducerSlice
export default reducer
