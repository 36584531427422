/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import { ReduxState } from '@reducers/index'
import WalletDispatcher from '@app/reducers/wallet/dispatcher'
import React from 'react'
import { useAsyncIntervalFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import BootstrapInput from '../../../elements/form/BootstrapInput'
import useWalletQuantityAvailable from '../../../hooks/wallet/useWalletQuantityAvailable'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import { Currency } from '@app/typings/core'

interface Props {
  currency: Currency
  value: string
  onChange(amount: string): any
}
function WithdrawFormAmount(props: Props) {
  const { currency, value, onChange } = props
  const { id: currency_id, symbol } = currency || {}

  const { asset } = useSelector((state: ReduxState) => ({
    asset: state.wallet[currency_id],
  }))

  const dispatch = useDispatch<ReduxDispatch>()
  useAsyncIntervalFetch(async () => {
    await dispatch(WalletDispatcher.get(currency_id))
  }, 30000)

  const { quantity_frozen = 0 } = asset || {}
  const quantity_available = useWalletQuantityAvailable(asset)

  const handleChange = (e) => {
    let inputValue = e.target.value
    inputValue = inputValue.replace(/[^\d.]/, '')

    // let nInput = Number(inputValue)
    // if (nInput > quantity_available) {
    //   nInput = quantity_available
    //   inputValue = nInput.toString()
    // }

    onChange(inputValue)
  }

  const handleClickMax = () => {
    onChange(quantity_available)
  }

  return (
    <div
      css={css`
        margin-top: 20px;
      `}
    >
      <div>
        <Typography>Amount</Typography>
      </div>
      <div
        css={css`
          position: relative;
          margin-bottom: 7px;
        `}
      >
        <BootstrapInput fullWidth placeholder={'amount'} value={value} onChange={handleChange} />

        <div
          css={css`
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
          `}
        >
          <Grid container alignItems={'center'}>
            <span
              onClick={handleClickMax}
              css={css`
                color: #c99614;
                cursor: pointer;

                &:hover {
                  color: #e2ac23;
                }
              `}
            >
              MAX
            </span>
            <div
              css={css`
                width: 1px;
                height: 10px;
                background-color: #6c6c6c;
                margin: 0 5px;
              `}
            />
            <span
              css={css`
                color: #6c6c6c;
              `}
            >
              {symbol}
            </span>
          </Grid>
        </div>
      </div>

      <div>
        <Grid container alignItems={'center'}>
          <Typography variant={'caption'}>Available for withdraw:</Typography>
          <Typography
            css={css`
              margin-left: 5px;
            `}
          >
            {quantity_available || 0} {symbol}
          </Typography>
        </Grid>

        {quantity_frozen ? (
          <Grid container alignItems={'center'}>
            <Typography variant={'caption'}>Locked in orders:</Typography>
            <Typography
              css={css`
                margin-left: 5px;
              `}
            >
              {quantity_frozen} {symbol}
            </Typography>
          </Grid>
        ) : null}
      </div>
    </div>
  )
}

export default WithdrawFormAmount
