/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import ChainItemWithdrawInfo, { Props as ChainItemWithdrawInfoProps } from './components/ChainItemWithdrawInfo'

export interface Props extends ChainItemWithdrawInfoProps {
  chain: Chain
  onSelect(chain: Chain): any
  type: 'withdraw' | 'deposit'

  /**
   * Chain warning is needed for withdraw. But deposit still works.
   */
  preventDisabledState?: boolean
}
function ChainItem(props: Props) {
  const { chain, onSelect, preventDisabledState, type, currency } = props
  const { id: chain_id, name, short_name, is_enabled, is_maintenance } = chain

  const isDisabled = !preventDisabledState && (!is_enabled || is_maintenance)

  const handleClick = () => {
    if (isDisabled) return null
    onSelect(chain)
  }

  return (
    <div
      onClick={handleClick}
      css={[
        css`
          padding: 15px 30px;
        `,
        isDisabled
          ? css`
              background-color: #f6dfdf;
            `
          : css`
              cursor: pointer;

              &:hover {
                background-color: #f1f1f1;
              }
            `,
      ]}
    >
      <Grid container>
        <Grid flexGrow={1}>
          <Typography fontWeight={'bold'} fontSize={14}>
            {short_name}
          </Typography>
          <Typography fontSize={12}>{name}</Typography>
        </Grid>

        {is_maintenance ? (
          <Grid alignSelf={'center'}>
            <div
              css={css`
                background-color: #dc2a3b;
                border-radius: 5px;
                padding: 3px 10px;
                display: inline-block;
              `}
            >
              <Typography
                css={css`
                  font-size: 10px;
                  color: #fff;
                `}
              >
                In Maintenance
              </Typography>
            </div>
          </Grid>
        ) : (
          <ChainItemWithdrawInfo chain={chain} currency={currency} type={type} />
        )}
      </Grid>
    </div>
  )
}

export default ChainItem
