import React from 'react'
import { Route, Routes } from 'react-router-dom'
import RouterContext from './RouterContext'
import LayoutRequireAuth from './navigators/LayoutRequireAuth'
// import AppContainer from './components/AppContainer'
// import AuthGuard from './components/AuthGuard'
// import ReNavigator from './ReNavigator'
// import SessionGuard from './components/SessionGuard'
import Login from './pages/auth/Auth'
import SignupPage from './pages/auth/SignupPage'
import SignupWaitForUser from './pages/auth/SignupWaitForUser'
import ChartPage from './pages/chart/ChartPage'
import EmailVerify from './pages/email/EmailVerify'
import Home from './pages/home/Home'
import Markets from './pages/markets/Markets'
import Privacy from './pages/system/Privacy'
import TokenInfoPage from './pages/token/TokenInfoPage'
import Trade from './pages/trade/Trade'
import WalletDeposit from './pages/wallet/WalletDeposit'
import WalletOverview from './pages/wallet/WalletOverview'
import WalletWithdraw from './pages/wallet/WalletWithdraw'
// import Auth from './pages/auth/Auth'
// import Signup from './pages/auth/SignupPage'
// import Settings from './pages/settings/Settings'

function AppRouter() {
  return (
    <RouterContext>
      <Routes>
        {/* <Route path="/" element={<App />}> */}
        <Route index element={<Home />} />

        {/* must be public */}
        <Route path={'email/verify/:code'} element={<EmailVerify />} />

        <Route path={'markets'} element={<Markets />} />
        <Route path={'trade/:key'} element={<Trade />} />
        <Route path={'chart/:key'} element={<ChartPage />} />

        <Route path={'login'} element={<Login />} />
        <Route path={'signup'} element={<SignupPage />} />
        <Route path={'signup-wait-for-user'} element={<SignupWaitForUser />} />

        <Route path={'token'} element={<TokenInfoPage />} />

        <Route path={'wallet'} element={<LayoutRequireAuth />}>
          <Route path="" element={<WalletOverview />} />
          <Route path={'deposit'} element={<WalletDeposit />} />
          <Route path={'withdraw'} element={<WalletWithdraw />} />
        </Route>

        <Route path="privacy" element={<Privacy />} />
        {/* <Route path="teams" element={<Teams />}> */}
        {/* <Route path=":teamId" element={<Team />} /> */}
        {/* <Route path="new" element={<NewTeamForm />} /> */}
        {/* <Route index element={<LeagueStandings />} /> */}
        {/* </Route> */}
        {/* </Route> */}
      </Routes>
    </RouterContext>
  )
}

export default AppRouter
