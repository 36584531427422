/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import React from 'react'

interface Props {
  title: string
  value: string
}

function Info(props: Props) {
  const { title, value } = props

  return (
    <Typography
      css={css`
        font-size: 12px;
      `}
    >
      <span
        css={css`
          color: #848484;
          margin-right: 5px;
        `}
      >
        {title}
      </span>
      {value}
    </Typography>
  )
}

export default Info
