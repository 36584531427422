import keyManager from 'constant-manager'
import { store } from '../../reducers/store'
import { MarketCandle } from '../typings/core'
import MarketDispatcher from '../reducers/markets/dispatcher'
import WalletDispatcher from '../reducers/wallet/dispatcher'
import socket from './socket'

const eventKeys = keyManager({
  MARKET_CANDLE_UPDATE: '',
  MARKET_ORDER_BOOK_UPDATED: '',
  USER_WALLET_UPDATED: 'USER/WALLET_UPDATED',
})

socket.on('connect', () => {
  console.log('socket connected.. do something here.')
})

socket.on('MARKET_UPDATE', (data) => {
  console.log('socket event:', 'MARKET_UPDATE', data)
  const { event, market_id } = data

  if (!market_id) return null
  if (event === eventKeys.MARKET_ORDER_BOOK_UPDATED) {
    store.dispatch<any>(MarketDispatcher.getOrderBook({ market_id }))
  }

  if (event === eventKeys.MARKET_CANDLE_UPDATE) {
    const { candle } = data as { candle: MarketCandle }
    if (!candle) {
      console.error('candle not found in event')
      return null
    }
    store.dispatch<any>(MarketDispatcher.actions.setMarketPrice({ market_id: candle.market_id, price: candle.close }))
    store.dispatch<any>(MarketDispatcher.actions.setActiveCandle({ candle }))
  }
})

socket.on('USER/WALLET_UPDATED', (data: { currency_id: string; user_id: string }) => {
  console.log('socket event:', 'USER/WALLET_UPDATED', data)
  const { currency_id, user_id } = data || {}
  if (currency_id) {
    store.dispatch<any>(WalletDispatcher.get(currency_id))
  }
})
