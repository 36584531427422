/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import MarketDispatcher from '@app/reducers/markets/dispatcher'
import { ReduxStateMarketsItem } from '@app/reducers/markets/reducer'
import React, { useMemo } from 'react'
import { useAsyncFetch, useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { useLocalStorage } from 'usehooks-ts'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import { MarketCandleInterval } from '@app/typings/core'
import Chart from '../chart/Chart'
import ChartTimeScaling, { HEIGHT as IntervalHeight } from '../chart/ChartTimeScaling'

interface Props {
  market: ReduxStateMarketsItem
  height?: number
}
function TradeChart(props: Props) {
  const { market, height } = props
  const dispatch = useDispatch<ReduxDispatch>()
  const [interval, setCandleInterval] = useLocalStorage<MarketCandleInterval>('settings/trade/interval', '15m')

  // use interval from store, because it is set together with candles update
  const { candles = [], candlesInterval = interval, key: market_key } = market

  const fetchCandles = useAsyncFetch(async () => {
    await dispatch(MarketDispatcher.getCandles({ market_key, interval }))
  }, [market.id, interval])

  const loadMoreAction = useAsyncHandler(async () => {
    await dispatch(MarketDispatcher.getCandlesPrevious({ market_key, interval }))
  })

  const heightNext = useMemo(() => {
    if (!height) return height

    return height - IntervalHeight
  }, [height])

  return (
    <div>
      <ChartTimeScaling scaling={interval} onChange={setCandleInterval} />
      <Chart data={candles} onLoadMore={loadMoreAction.execute} candlesInterval={candlesInterval} height={heightNext} />
    </div>
  )
}

export default TradeChart
