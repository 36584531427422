/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { Link } from 'react-router-dom'
import config from '../../config'
import useIsLoggedIn from '../../hooks/useIsLoggedIn'
import WalletLinks from './WalletLinks'

const pages = [
  {
    label: 'Home',
    href: '/',
  },
  {
    label: 'Markets',
    href: '/markets',
  },
]

const ResponsiveAppBar = () => {
  let isLoggedIn = useIsLoggedIn()

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <AppBar
      position="static"
      css={css`
        background-color: #2b5b72;
        color: #fff;
      `}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: 'flex' }, mr: 1 }} /> */}
          <div
            css={css`
              margin-right: 2rem;
              position: relative;
            `}
          >
            <Grid container alignItems={'center'}>
              <Typography
                variant="h5"
                noWrap
                component="a"
                href="/"
                css={css`
                  color: #fff;
                  margin-right: 5px;
                `}
                sx={{
                  display: { xs: 'flex' },
                  flexGrow: 1,
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                Octoex
              </Typography>
              <div>
                <Typography
                  css={css`
                    color: #fff;
                    background-color: #d12424;
                    padding: 3px 5px;
                    border-radius: 4px;
                    font-size: 11px;
                    user-select: none;
                    letter-spacing: 1px;
                    margin-top: 2px;
                  `}
                >
                  BETA
                </Typography>
              </div>
            </Grid>

            <Typography
              css={css`
                color: #fff;
                font-size: 10px;
                position: absolute;
                top: 100%;
                left: 0;
              `}
            >
              v{config.version}
            </Typography>
          </div>

          <Box sx={{ flexGrow: 1, display: { md: 'flex' } }}>
            {pages.map(({ label, href }) => (
              <Box sx={{ padding: '10px', margin: '0 20px' }} key={`menu-${label}`}>
                <Link to={href}>{label}</Link>
              </Box>
            ))}
          </Box>

          <div
            css={css`
              align-self: stretch;
            `}
          >
            <Grid
              container
              alignItems={'stretch'}
              css={css`
                height: 100%;
                margin-right: 50px;
              `}
            >
              {isLoggedIn ? (
                <WalletLinks />
              ) : (
                <Grid container alignItems={'center'}>
                  <Link to={'/login'}>Login</Link>
                </Grid>
              )}
            </Grid>
          </div>
          {/* <Box sx={{ flexGrow: 0 }}> */}
          {/*  <Tooltip title="Open settings"> */}
          {/*    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} size="large"> */}
          {/*      <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
          {/*    </IconButton> */}
          {/*  </Tooltip> */}
          {/*  <Menu */}
          {/*    sx={{ mt: '45px' }} */}
          {/*    id="menu-appbar" */}
          {/*    anchorEl={anchorElUser} */}
          {/*    anchorOrigin={{ */}
          {/*      vertical: 'top', */}
          {/*      horizontal: 'right', */}
          {/*    }} */}
          {/*    keepMounted */}
          {/*    transformOrigin={{ */}
          {/*      vertical: 'top', */}
          {/*      horizontal: 'right', */}
          {/*    }} */}
          {/*    open={Boolean(anchorElUser)} */}
          {/*    onClose={handleCloseUserMenu} */}
          {/*  > */}
          {/*    {settings.map((setting) => ( */}
          {/*      <MenuItem key={setting} onClick={handleCloseUserMenu}> */}
          {/*        <Typography textAlign="center">{setting}</Typography> */}
          {/*      </MenuItem> */}
          {/*    ))} */}
          {/*  </Menu> */}
          {/* </Box> */}
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default ResponsiveAppBar
