import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import appTypes from './types'

export interface State {
  fees: {
    taker_fee: number
    maker_fee: number
  }
}

export const initialState: State = {
  fees: {
    taker_fee: 0,
    maker_fee: 0,
  },
}
const slice = createSlice({
  name: 'APP',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(appTypes.RESET, () => initialState)
  },
  reducers: {
    setFees(state, action: PayloadAction<State['fees']>) {
      state.fees = action.payload
    },
  },
})

export const { reducer, actions } = slice
export default reducer
