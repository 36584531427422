import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from '../../typings/core'
import appTypes from '../app/types'

interface State extends User {}

const initialState: State = {} as any

const slice = createSlice({
  name: 'ME',
  initialState,
  extraReducers: (builder) => builder.addCase(appTypes.RESET, () => initialState),
  reducers: {
    set(state, action: PayloadAction<User>) {
      return { ...state, ...action.payload }
    },
  },
})

export const { reducer, actions } = slice
export default reducer
