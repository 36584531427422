import { DispatcherResponse } from '../../../typings/ReduxDispatch'
import { actions as listsActions } from '../lists/reducer'
import api, { WalletWithdrawParams } from './api'
import { actions } from './reducer'

class Dispatcher {
  actions = actions

  getList(): DispatcherResponse {
    return async (dispatch) => {
      const list = await api.getList()
      dispatch(listsActions.setWalletList(list))
      dispatch(actions.setItems(list))
    }
  }

  get(currency_id: string): DispatcherResponse {
    return async (dispatch) => {
      try {
        const item = await api.get(currency_id)
        if (!item) return null

        dispatch(actions.setItem(item))
      } catch (e) {
        const status = e.response?.status
        if (status === 403) {
          // wallet is not created (empty)
          return null
        }

        throw e
      }
    }
  }

  withdrawRequest(currency_id: string, params: WalletWithdrawParams): DispatcherResponse {
    return async (dispatch) => {
      const list = await api.withdraw(currency_id, params)
      dispatch(this.get(currency_id))
      dispatch(this.getWithdrawHistory(currency_id))
    }
  }

  getWithdrawHistory(currency_id: string): DispatcherResponse {
    return async (dispatch) => {
      const list = await api.getWithdrawHistory(currency_id)
      dispatch(actions.setWithdrawList({ id: currency_id, list }))
    }
  }
}

const WalletDispatcher = new Dispatcher()
export default WalletDispatcher
