/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import WalletDispatcher from '@app/reducers/wallet/dispatcher'
import { ReduxStateWallet } from '@app/reducers/wallet/reducer'
import _ from 'lodash'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import TransactionHistoryItem from './components/TransactionHistoryItem'
import TransactionHistoryTable from './components/TransactionHistoryTable'

interface Props {
  wallet: ReduxStateWallet
}
function WithdrawHistory(props: Props) {
  const { wallet } = props
  const { id: currency_id, withdrawals } = wallet || {}
  const dispatch = useDispatch<ReduxDispatch>()

  const withdrawAction = useAsyncFetch(async () => {
    await dispatch(WalletDispatcher.getWithdrawHistory(currency_id))
  }, [currency_id])

  return (
    <div
      css={css`
        padding-left: 30px;
      `}
    >
      <Typography
        variant={'h3'}
        css={css`
          margin-top: 30px;
          margin-bottom: 10px;
          font-size: 18px;
        `}
      >
        Withdrawals:
      </Typography>

      <TransactionHistoryTable>
        {_.map(withdrawals, (item) => (
          <TransactionHistoryItem item={item} key={`transaction-history-${item.id}`} />
        ))}
      </TransactionHistoryTable>
    </div>
  )
}

export default WithdrawHistory
