import CurrencyBucketDispatcher from '@app/reducers/currencyBucket/dispatcher'
import { ReduxState } from '@reducers/index'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxDispatch } from '../typings/ReduxDispatch'

function useCurrency(currency_id: string) {
  const { currency } = useSelector((state: ReduxState) => ({
    currency: state.currencyBucket[currency_id],
  }))

  const dispatch = useDispatch<ReduxDispatch>()
  useAsyncFetch(async () => {
    if (!currency_id) return null
    await dispatch(CurrencyBucketDispatcher.getItem(currency_id))
  }, [currency_id])

  return currency
}

export default useCurrency
