import invariant from 'invariant'
import _ from 'lodash'
import { DispatcherResponse } from '../../../typings/ReduxDispatch'
import { Market, MarketCandleInterval } from '../../typings/core'
import { actions as listsActions } from '../lists/reducer'
import OrderDispatcher from '../orders/dispatcher'
import WalletDispatcher from '../wallet/dispatcher'
import apiMarkets, { OrderCreatePayload } from './api'
import { actions } from './reducer'

class Dispatcher {
  actions = actions

  getList(params: { search: string }): DispatcherResponse {
    return async (dispatch) => {
      const result = await apiMarkets.getList(params)

      dispatch(listsActions.setMarkets(result.list || []))
    }
  }

  getPopularList(): DispatcherResponse {
    return async (dispatch, getState) => {
      const list = getState().lists.marketsPopular
      if (!_.isEmpty(list)) return null

      const result = await apiMarkets.getList({})

      dispatch(listsActions.setPopularMarkets(result.list || []))
    }
  }

  get({ market_key }: { market_key: string }): DispatcherResponse {
    return async (dispatch) => {
      const market = await apiMarkets.get({ market_key })
      if (!market) return null

      await dispatch(actions.set(market))
    }
  }

  getOrderBook({ market_id }: { market_id: string }): DispatcherResponse {
    return async (dispatch) => {
      const orderBook = await apiMarkets.getOrderBook(market_id)
      if (!orderBook) return null

      dispatch(actions.setOrderBook({ market_id, data: orderBook }))
    }
  }

  // getOrders(market_id: string) {
  //   return async (dispatch) => {
  //     const orders = await apiMarkets.getOrders(market_id)
  //     dispatch(actions.setOrders({ market_id, orders }))
  //   }
  // }

  createOrder(market_id: string, params: OrderCreatePayload) {
    return async (dispatch) => {
      await apiMarkets.createOrder(market_id, params)
      // dispatch(this.getOrders(market_id))
      dispatch(this.refreshUserDataRelatedToMarket({ market_id }))
      dispatch(OrderDispatcher.getOrders({ isForce: true }))
    }
  }

  getCandles({ market_key, interval }: { market_key: string; interval: MarketCandleInterval }) {
    return async (dispatch) => {
      const candles = await apiMarkets.getCandles(market_key, { interval })

      dispatch(actions.setCandles({ market_key, candles, interval }))
    }
  }

  getCandlesPrevious({ market_key, interval }: { market_key: string; interval: string | number }): DispatcherResponse {
    return async (dispatch, getState) => {
      const state = getState()
      const market = state.markets[market_key]
      invariant(market, 'market is not found')
      if (market.isCandlesDisabled) {
        return null
      }

      const firstCandle = _.head(market.candles)
      if (!firstCandle) {
        console.error('no candle to continue fetching')
        return null
      }

      const candles = await apiMarkets.getCandles(market_key, { endTime: firstCandle.time, interval })
      dispatch(actions.setPreviousCandles({ market_key, candles }))
    }
  }

  getWalletsByMarket(market_id: string) {
    return async (dispatch) => {
      const wallets = await apiMarkets.getWallets(market_id)
      dispatch(WalletDispatcher.actions.setItems(wallets))
    }
  }

  refreshUserDataRelatedToMarket({
    market_key,
    market_id,
  }: Partial<{ market_key: string; market_id: string }>): DispatcherResponse {
    return async (dispatch, getState) => {
      const { markets } = getState()
      let market: Market | undefined
      if (market_key) market = markets[market_key]
      if (market_id) market = _.find(markets, { id: market_id })

      if (!market) {
        console.log('refreshUserDataRelatedToMarket(): market is not found ')
        return null
      }

      dispatch(WalletDispatcher.get(market.currency_id))
      dispatch(WalletDispatcher.get(market.currency_secondary_id))
    }
  }

  onIntervalRefresh(market_id: string): DispatcherResponse {
    return async (dispatch, getState) => {
      const { token } = getState().auth
      if (!token) return null

      await dispatch(this.getWalletsByMarket(market_id))
    }
  }
}

const MarketDispatcher = new Dispatcher()
export default MarketDispatcher
