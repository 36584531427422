import Big from 'big.js'
import { useMemo } from 'react'
import { UserAssetCurrency } from '@app/typings/core'

function useWalletQuantityAvailable(asset?: UserAssetCurrency) {
  const { quantity, quantity_frozen } = asset || {}

  const quantity_available = useMemo(() => {
    if (!asset) return ''

    const qN = new Big(quantity || '0')
    const qfN = new Big(quantity_frozen || '0')
    return qN.minus(qfN).toString()
  }, [quantity, quantity_frozen])

  return quantity_available
}

export default useWalletQuantityAvailable
