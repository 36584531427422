/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { faSortDown, faWallet } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, MenuItem, Typography } from '@mui/material'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { ReduxDispatch } from '../../typings/ReduxDispatch'

const cssMenuDisplay = css`
  display: none;
`

const WalletLinks = () => {
  const navigate = useNavigate()
  // const dispatch = useDispatch<ReduxDispatch>()

  const handleClickOverview = () => navigate('/wallet')
  // const handleClickLogout = () => {
  //   navigate('/', { replace: true })
  //   dispatch(AuthDispatcher.logout())
  // }

  return (
    <Grid
      container
      alignItems={'stretch'}
      css={css`
        position: relative;
        cursor: pointer;

        &:hover .menu-container {
          display: block;
        }
      `}
    >
      <Grid container alignItems={'center'}>
        <Link to={'/wallet'}>
          <Grid container alignItems={'center'}>
            <FontAwesomeIcon icon={faWallet} />
            <Typography
              css={css`
                color: inherit;
                margin-left: 10px;
              `}
            >
              wallet
            </Typography>
          </Grid>
        </Link>

        {/* <div */}
        {/*  css={css` */}
        {/*    margin-left: 5px; */}
        {/*    margin-top: -4px; */}
        {/*  `} */}
        {/* > */}
        {/*  <FontAwesomeIcon icon={faSortDown} /> */}
        {/* </div> */}
      </Grid>

      {/* <div */}
      {/*  className={'menu-container'} */}
      {/*  css={[ */}
      {/*    css` */}
      {/*      position: absolute; */}
      {/*      right: 0; */}
      {/*      top: 100%; */}
      {/*      min-width: 200px; */}
      {/*      background-color: #fff; */}
      {/*      border-radius: 5px; */}
      {/*      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2); */}
      {/*    `, */}
      {/*    cssMenuDisplay, */}
      {/*  ]} */}
      {/* > */}
      {/*  <div> */}
      {/*    <MenuItem onClick={handleClickOverview}> */}
      {/*      <Typography textAlign="center">Overview</Typography> */}
      {/*    </MenuItem> */}

      {/*    /!* <MenuItem onClick={handleClickLogout}> *!/ */}
      {/*    /!*  <Typography textAlign="center">Logout</Typography> *!/ */}
      {/*    /!* </MenuItem> *!/ */}
      {/*  </div> */}
      {/* </div> */}
    </Grid>
  )
}
export default WalletLinks
