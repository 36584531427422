/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import AuthDispatcher from '@app/reducers/auth/dispatcher'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { RendererStatusSplit } from 'react-renderer-status-split'
import validator from 'validator'
import Loader from '../../../components/Loader'
import BootstrapInput from '../../../elements/form/BootstrapInput'
import { useAppDispatch } from '../../../libs/redux'

interface Props {
  onLoginSuccess(): any
}

function LocalSignUp(props: Props) {
  const { onLoginSuccess } = props
  const form = useForm<{ email: string; password: string; password_2: string }>({
    reValidateMode: 'onSubmit',
    criteriaMode: 'all',
  })
  const {
    formState: { errors },
  } = form

  const dispatch = useAppDispatch()
  const submitAction = useAsyncHandler(async (params: { email: string; password: string }) => {
    await dispatch(AuthDispatcher.signupWithEmailAndPassword(params))
    onLoginSuccess()
  })

  const onSubmit = form.handleSubmit(submitAction.execute)

  return (
    <div
      css={css`
        input {
          margin-bottom: 20px;
        }
      `}
    >
      <BootstrapInput
        {...form.register('email', {
          required: true,
          validate(email) {
            return validator.isEmail(email)
          },
        })}
        title={'Email'}
        type="email"
        placeholder={'Email'}
        fullWidth
        error={!!errors.email}
      />

      <BootstrapInput
        {...form.register('password', { required: true, min: 8, max: 20 })}
        title={'Password'}
        type="password"
        placeholder={'Password'}
        error={!!errors.password}
        fullWidth
      />

      <BootstrapInput
        {...form.register('password_2', {
          required: true,
          min: 8,
          max: 20,
          validate(password_2: string) {
            const state = form.getValues()
            return password_2 === state.password
          },
        })}
        title={'Repeat Password'}
        type="password"
        placeholder={'Repeat Password'}
        error={!!errors.password_2}
        fullWidth
      />

      <RendererStatusSplit
        statuses={submitAction}
        renderLoading={() => (
          <Grid container alignItems={'center'} justifyContent={'center'}>
            <Loader />
          </Grid>
        )}
        render={() => (
          <div
            css={css`
              padding-top: 20px;
              padding-bottom: 20px;
            `}
          >
            <Button onClick={onSubmit} variant={'contained'} color={'primary'} fullWidth>
              Create an account
            </Button>
            {submitAction.error && <Typography color={'error'}>{submitAction.error}</Typography>}
          </div>
        )}
      />
    </div>
  )
}

export default LocalSignUp
