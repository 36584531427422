import { useEffect } from 'react'
import socket from '../socket'

function useMarketSubscription(market_key: string) {
  useEffect(() => {
    if (!market_key) return
    socket.emit('MARKET_EVENTS_START', market_key)
    return () => {
      socket.emit('MARKET_EVENTS_STOP', market_key)
    }
  }, [market_key])
}

export default useMarketSubscription
