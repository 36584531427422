import { Grid, Typography } from '@mui/material'
import AppDispatcher from '@reducers/app/dispatcher'
import AuthDispatcher from '@app/reducers/auth/dispatcher'
import { ReduxState } from '@reducers/index'
import React, { Suspense } from 'react'
import { connect } from 'react-redux'
import LoadingModal from '../modals/LoadingModal'
import { ReduxDispatch } from '../typings/ReduxDispatch'
import { ExtractConnectType } from '../typings/ReduxExtractor'
import Loader from './Loader'

const ua = window.navigator.userAgent
const isIE = /MSIE|Trident/.test(ua)

interface State {
  isReady: boolean
  error?: string
}
class AppLauncher extends React.PureComponent<ExtractConnectType<typeof connectStore>, State> {
  state = {
    isReady: false,
    error: '',
  }

  render() {
    const { isReady, error } = this.state
    if (error)
      return (
        <Grid container justifyContent={'center'}>
          <Typography variant={'error'} style={{ color: 'red', marginTop: 40 }}>
            {error}
          </Typography>
        </Grid>
      )

    if (!isReady) return <LoadingModal isVisible onClose={() => {}} message={`Loading data`} />
    const { children } = this.props

    if (isIE) {
      return <div style={{ textAlign: 'center', padding: 20 }}>We don't support IE, try any other browser.</div>
    }

    return <Suspense fallback={<Loader />}>{children}</Suspense>
  }

  async handleInstall() {
    try {
      await this.props.useCredentials()
      await this.installSafe()
      this.setState({ isReady: true })
    } catch (e) {
      this.setState({ error: e.message })
    }
  }

  async installSafe() {
    try {
      await this.props.install()
    } catch (e) {
      console.error('installSafe error', e.message)
    }
  }

  componentDidMount(): void {
    this.handleInstall()
  }
}

const connectStore = connect(
  (state: ReduxState) => ({}),
  (dispatch: ReduxDispatch) => ({
    useCredentials: () => dispatch(AuthDispatcher.useCredentials()),
    install: () => dispatch(AppDispatcher.install()),
  }),
)

export default connectStore(AppLauncher)
