/** @jsx jsx */
import { WalletWithdrawParams } from '@app/reducers/wallet/api'
import WalletDispatcher from '@app/reducers/wallet/dispatcher'
import { Currency } from '@app/typings/core'
import { jsx, css } from '@emotion/react'
import { Button, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import Loader from '../../../components/Loader'
import BootstrapInput from '../../../elements/form/BootstrapInput'
import RendererActionSplit from '../../../libs/RendererActionSplit'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import MockedInput from '../deposit/components/MockedInput'
import WithdrawFormAmount from './WithdrawFormAmount'
import WithdrawFormFeeInfo from './components/WithdrawFormFeeInfo'

interface Props {
  currency: Currency
  chain?: Chain
  onChainClick(): any
}
function WithdrawForm(props: Props) {
  const { currency, chain, onChainClick } = props
  const { id: currency_id } = currency || {}
  const [amount, setAmount] = useState<string>('')
  const [address, setAddress] = useState('')
  const dispatch = useDispatch<ReduxDispatch>()
  const { id: chain_id, name, short_name, withdraw_info } = chain || {}
  const { withdraw_fee } = withdraw_info || {}

  const form: WalletWithdrawParams = { amount, address, chain_id, fee: withdraw_fee }
  const isValid = isFormValid(form)

  const withdrawAction = useAsyncHandler(async () => {
    if (!isFormValid(form)) return null

    await dispatch(WalletDispatcher.withdrawRequest(currency_id, form))
  })

  function isFormValid(form: WalletWithdrawParams) {
    if (!form.address) return false
    if (!form.amount) return false
    if (!form.chain_id) return false

    return true
  }

  const handleChangeAddress = (e) => setAddress(e.target.value)

  return (
    <div
      css={css`
        margin-top: 20px;
      `}
    >
      <div
        css={css`
          margin-bottom: 10px;
        `}
      >
        <div>
          <Typography>Address</Typography>
        </div>
        <BootstrapInput value={address} fullWidth placeholder={'address'} onChange={handleChangeAddress} />
      </div>

      <div>
        <div>
          <Typography>Chain</Typography>
        </div>
        <MockedInput short_name={short_name} name={name} placeholder={'Select Network'} onClick={onChainClick} />

        <WithdrawFormFeeInfo chain={chain} />
      </div>

      <WithdrawFormAmount currency={currency} value={amount} onChange={setAmount} />

      <Grid
        container
        justifyContent={'center'}
        css={css`
          margin-top: 20px;
        `}
      >
        <RendererActionSplit
          statuses={withdrawAction}
          renderLoading={() => (
            <Grid container justifyContent={'center'}>
              <Loader />
            </Grid>
          )}
          renderSuccess={() => <Typography textAlign={'center'}>Withdraw created!</Typography>}
          render={() => (
            <Grid container alignItems="center" justifyContent={'center'} flexDirection={'column'}>
              <Button color={'primary'} variant={'contained'} onClick={withdrawAction.execute} disabled={!isValid}>
                Withdraw
              </Button>

              <div>
                {withdrawAction.error && (
                  <Typography textAlign={'center'} color={'error'}>
                    {withdrawAction.error}
                  </Typography>
                )}
              </div>
            </Grid>
          )}
        />
      </Grid>
    </div>
  )
}

export default WithdrawForm
