import SeamlessImmutable, { Immutable } from 'seamless-immutable'
import types from './types'

interface State {
  list: any[]
}

const initialState: State = {
  list: [],
}

export default function currencyReducer(state = SeamlessImmutable(initialState), action: ReduxAction): Immutable<State> {
  switch (action.type) {
    case types.SET_LIST: {
      const list = action.payload
      return state.merge({
        list,
      })
    }

    default:
      return state
  }
}
