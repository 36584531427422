/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  market_key: string
  children: ReactElement
}

function LinkTrade(props: Props) {
  const { market_key, children } = props

  const href = `/trade/${market_key}`

  return <Link to={href} {...props}>{children}</Link>
}

export default LinkTrade
