import apiClient from '@app/api/apiClient'

async function getMe() {
  const { data } = await apiClient.get(`/me`)
  return data
}

export default {
  getMe,
}
