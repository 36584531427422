/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import { Currency } from '@app/typings/core'

export interface Props {
  currency: Currency
  chain: Chain
  type: 'withdraw' | 'deposit'
}
function ChainItemWithdrawInfo(props: Props) {
  const { chain, currency, type } = props
  if (type !== 'withdraw') return null

  const { withdraw_info } = chain
  const { withdraw_fee, withdraw_fee_stable } = withdraw_info || {}
  if (!withdraw_fee) return null

  const { symbol } = currency || {}

  return (
    <div>
      <Grid container flexDirection={'column'} alignItems={'flex-end'}>
        <Typography
          css={css`
            font-size: 14px;
            font-weight: bold;
          `}
        >
          {withdraw_fee} {symbol}
        </Typography>
        <Typography
          css={css`
            font-size: 12px;
            color: #b1b1b1;
          `}
        >
          (~${withdraw_fee_stable})
        </Typography>
      </Grid>
    </div>
  )
}

export default ChainItemWithdrawInfo
