/**
 * process.env.API_URL should be only this style.
 * When there other way it can be not replaced. For example: const { API_URL } = process.env
 */
import invariant from 'invariant'
import moment from 'moment'

const isTest = process.env.NODE_ENV === 'test'
const version = process.env.VERSION
const appVersion = process.env.APP_VERSION
const buildTime = process.env.BUILD_TIME
const app = process.env.APP

console.log('buildTime', buildTime)

console.log(`
********************************************
******  APP: ${app} (${version})
********************************************
`)

const config = {
  version,
  appVersion,
  env: process.env.APP_ENV,
  api_url: process.env.API_URL as string,
  socket_url: process.env.SOCKET_URL as string,
  assets_api_url: process.env.ASSETS_API_URL as string,
  wallet_url: process.env.WALLET_URL as string,

  userino: {
    url: process.env.USERINO_URL as string,
    app_id: process.env.USERINO_APP as string,
  },

  timeout: process.env.APP_ENV === 'production' ? 120000 : 30000,

  PORT: 3366,
  bugsnag_api_key: '033ec56f6440b2ab13fabd3d7c6ed3ec',
  // google_api_key: 'AIzaSyCaUJ8uieZIvOAlQGAcOOo99meQrbOa5MQ',

  loggers: {
    isStoreEnabled: true, //! isTest,
  },

  contacts: {
    email: 'public@octoex.com',
    twitter: '@octoex',
    telegram_announce: 'https://t.me/octoex_com',
    telegram_chat: 'https://t.me/octoex_chat',
  },

  links: {
    googlePlayStore: 'https://play.google.com/store/apps/details?id=com.octoguild.octoex',
  },
}

if (!isTest) {
  console.log('env', config.env)
  if (buildTime) {
    console.log('Build Time:', moment(buildTime).toISOString())
  }
  console.log('config', config)
}

invariant(config.api_url, 'config.api_url is not set')

// @ts-ignore
window.config = config

export default config
