import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import invariant from 'invariant'
import appTypes from '@app/reducers/app/types'
import { Currency } from '../../typings/core'

interface State {
  [key: string]: ReduxStateChainItem
}

export interface ReduxStateChainItem {
  id: string
  name: string
  depositAddress?: {
    address: string
  }
}

const initialState: State = {}

const reducerSlice = createSlice({
  name: 'CHAINS',
  initialState,
  extraReducers: (builder) => builder.addCase(appTypes.RESET, () => initialState),
  reducers: {
    set(state, action: PayloadAction<Currency>) {
      const item: Currency = action.payload

      const stateItem = state[item.id]
      state[item.id] = { ...stateItem, ...item }
      // if (stateItem) {
      //   Object.assign(stateItem, market)
      // } else {
      //   state[market.key] = {
      //     ...market,
      //     orders: [],
      //   }
      // }
    },

    setDepositAddress(
      state,
      action: PayloadAction<{ id: string; depositAddress: ReduxStateChainItem['depositAddress'] }>,
    ) {
      const { id, depositAddress } = action.payload

      let stateItem = state[id]
      invariant(stateItem, 'currency is not found')
      stateItem.depositAddress = depositAddress
    },
  },
})

export const { actions, reducer } = reducerSlice
export default reducer
