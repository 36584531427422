/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import React from 'react'

interface Props {
  chain: Chain
}
function WithdrawFormFeeInfo(props: Props) {
  const { chain } = props
  const { withdraw_info } = chain || {}
  const { withdraw_fee, withdraw_fee_stable } = withdraw_info || {}

  if (!withdraw_fee) return null

  return (
    <div
      css={css`
        margin-top: 10px;
      `}
    >
      <Grid container alignItems={'center'}>
        <Typography
          lineHeight={'1.5rem'}
          css={css`
            font-size: 12px;
          `}
        >
          Withdraw Fee:
        </Typography>

        <Typography
          lineHeight={'1.5rem'}
          css={css`
            font-size: 14px;
            margin-left: 10px;
          `}
        >
          <span
            css={css`
              font-weight: bold;
            `}
          >
            {withdraw_fee}
          </span>
          {withdraw_fee_stable ? (
            <span
              css={css`
                color: #a0ac99;
                display: inline-block;
                margin-left: 5px;
              `}
            >
              (${withdraw_fee_stable})
            </span>
          ) : null}
        </Typography>
      </Grid>
    </div>
  )
}

export default WithdrawFormFeeInfo
