import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import Loader from '../components/Loader'
import ModalStyled from '../elements/ModalStyled'

const useStyles = makeStyles(
  {
    container: {
      minWidth: 200,
      paddingTop: 20,
    },
    loaderContainer: {
      paddingTop: 30,
      paddingBottom: 30,
      paddingRight: 20,
    },
    title: {
      marginBottom: 20,
    },
  },
  {
    classNamePrefix: 'LoadingModal',
  },
)

interface Props {
  message?: string
  isVisible: boolean
  onClose(): any
}

function LoadingModal(props: Props) {
  const { isVisible, onClose, message } = props
  const classes = useStyles()

  let title = message || 'Loading'

  return (
    <ModalStyled isVisible={isVisible} onClose={onClose} className={classes.container}>
      <Typography variant={'h4'} className={classes.title}>
        {title}
      </Typography>
      <Grid container justifyContent={'center'} className={classes.loaderContainer}>
        <Loader scale={1} />
      </Grid>
    </ModalStyled>
  )
}

export default LoadingModal
