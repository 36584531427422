/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import useResizeObserver from '@react-hook/resize-observer'
import { CandlestickData, createChart, CrosshairMode, IChartApi, ISeriesApi } from 'lightweight-charts'
import _ from 'lodash'
import React, { useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { MarketCandleInterval } from '@app/typings/core'
import useCandlesWithFilledEmptySpaces from '../hooks/useCandlesWithFilledEmptySpaces'

const HEIGHT_DEFAULT = 400

interface CandleItem {
  open: number
  high: number
  low: number
  time: string
}
interface Props {
  data: CandleItem[]
  onLoadMore(): any
  candlesInterval: MarketCandleInterval
  height?: number
}
function Chart(props: Props) {
  const { data, onLoadMore, candlesInterval, height } = props
  const chartContainerRef = useRef<HTMLDivElement>(null)
  const chart = useRef<IChartApi>()
  const candleSeriesRef = useRef<ISeriesApi<'Candlestick'>>()

  const initRef = useRef()
  const candles: CandlestickData[] = useMemo(() => {
    return _.map(data, (item) => {
      return {
        time: Number(item.time) / 1000, // .format('YYYY-MM-DD'),
        open: Number(item.open),
        close: item.close ? Number(item.close) : Number(item.open),
        high: Number(item.high),
        low: Number(item.low),
      }
    })
  }, [data])

  const candlesFull = useCandlesWithFilledEmptySpaces({ candles, candlesInterval })

  useEffect(() => {
    if (_.isEmpty(candlesFull)) return
    if (!candleSeriesRef.current) return

    let candleSeries = candleSeriesRef.current
    console.log('setData', candlesFull)

    const lastCandle = candles[candles.length - 1]
    candleSeries.setData(candlesFull)
    candleSeries.update(lastCandle)
  }, [chart, candlesFull])

  useLayoutEffect(() => {
    if (!chartContainerRef.current) return
    if (initRef.current) {
      console.error('Chart is initialized already.')
      return
    }
    initRef.current = true

    chart.current = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight,
      crosshair: {
        mode: CrosshairMode.Normal,
      },
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
        lockVisibleTimeRangeOnResize: true,
        // time
      },
    })

    const candleSeries = chart.current.addCandlestickSeries({})
    candleSeriesRef.current = candleSeries

    function onVisibleLogicalRangeChanged(newVisibleLogicalRange) {
      const barsInfo = candleSeries.barsInLogicalRange(newVisibleLogicalRange)
      // if there less than 50 bars to the left of the visible area
      if (barsInfo !== null && barsInfo.barsBefore < 50) {
        onLoadMore()
      }
    }

    chart.current.timeScale().subscribeVisibleLogicalRangeChange(onVisibleLogicalRangeChanged)
  }, [chartContainerRef])

  useResizeObserver(chartContainerRef, (entry) => {
    const { width, height } = entry.contentRect
    if (!chart.current) return null

    chart.current.applyOptions({ width, height })
    setTimeout(() => {
      chart.current?.timeScale().fitContent()
    }, 0)
  })

  return (
    <div
      css={css`
        padding-left: 10px;
      `}
    >
      <div
        id={'chart'}
        ref={chartContainerRef}
        style={{
          height: height || HEIGHT_DEFAULT,
        }}
      />
    </div>
  )
}

export default Chart
