import Big from 'big.js'
import { useMemo } from 'react'

function useTotal(props: { price: number | string; quantity: number | string }): number {
  const { price, quantity } = props

  const total = useMemo(() => {
    const priceBig = new Big(price || 0)
    const quantityBig = new Big(quantity || 0)
    return priceBig.mul(quantityBig).toNumber()
  }, [price, quantity])

  return total
}

export default useTotal
