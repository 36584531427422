import AuthDispatcher from '@app/reducers/auth/dispatcher'
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth'
import React from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import googleIcon from '../../assets/google-icon.svg'
import LoginButton from './LoginButton'

const logoutFirebaseSafe = async () => {
  try {
    const auth = getAuth()
    await signOut(auth)
  } catch (e) {
    console.log('ERROR [logoutGoogle]', e)
  }
}

const getFirebaseToken = async (): Promise<string | null> => {
  try {
    const { currentUser } = getAuth()
    if (!currentUser) {
      return null
    }

    const token = await currentUser.getIdToken()
    if (!token) {
      return null
    }

    return token
  } catch (e) {
    console.log('ERR [handleFirebaseLogin]:', e)
  }

  return null
}

interface Props {
  onLoggedIn?(): any
}

function GoogleLoginButton(props: Props) {
  const { onLoggedIn } = props
  const dispatch = useDispatch<ReduxDispatch>()

  const actionLogin = useAsyncHandler(async () => {
    await logoutFirebaseSafe()

    const auth = getAuth()
    const provider = new GoogleAuthProvider()
    await signInWithPopup(auth, provider)

    console.log('Google handleFirebaseLogin')
    const fireAuthToken = await getFirebaseToken()
    if (!fireAuthToken) return null

    await dispatch(AuthDispatcher.loginWithFirebaseToken(fireAuthToken))

    if (onLoggedIn) onLoggedIn()
  })

  return <LoginButton title={'Sign in with Google'} icon={googleIcon} onLogin={actionLogin.execute} />
}

export default GoogleLoginButton
