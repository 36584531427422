import appTypes from '@app/reducers/app/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Currency, Market, UserAssetCurrency, WalletCurrency } from '../../typings/core'

interface State {
  walletList: WalletCurrency[]
  markets: Market[]
  marketsPopular: Market[]
  currencies: Currency[] // todo implement
}

const initialState: State = {
  walletList: [],
  markets: [],
  marketsPopular: [],
  currencies: [],
}

const slice = createSlice({
  name: 'LIST',
  initialState,
  extraReducers: (builder) =>
    builder.addCase(appTypes.RESET, (state) => {
      state.walletList = []
    }),
  reducers: {
    setWalletList(state, action: PayloadAction<WalletCurrency[]>) {
      state.walletList = action.payload
    },

    setMarkets(state, action: PayloadAction<State['markets']>) {
      state.markets = action.payload
    },

    setPopularMarkets(state, action: PayloadAction<State['markets']>) {
      state.marketsPopular = action.payload
    },

    setCurrencies(state, action: PayloadAction<State['currencies']>) {
      state.currencies = action.payload
    },
  },
})

export const { actions, reducer } = slice
// export const dashboardActions = actions
// export const dashboardReducer = reducer
export default reducer
