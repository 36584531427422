/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React from 'react'

const cssItemContainer = css`
  flex: 1 1 0%;
  text-align: center;
  position: relative;
  display: flex;
`

const cssLabelWrapper = css`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex: 1 1 0%;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
  height: 32px;
  text-transform: uppercase;
`

const cssLabel = css`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

interface Props {
  type: 'SELL' | 'BUY'
  onChange(type: 'SELL' | 'BUY'): any
}

function TradeOrderTypeToggle(props: Props) {
  const { type, onChange } = props

  const handleClickBuy = () => onChange('BUY')
  const handleClickSell = () => onChange('SELL')

  return (
    <div
      css={css`
        box-sizing: border-box;
        margin: 0px 0px 12px;
        min-width: 0px;
        display: flex;
        font-weight: 500;
        border-radius: 4px;
      `}
    >
      <div className={'active'} onClick={handleClickBuy} css={cssItemContainer}>
        <div
          css={[
            cssLabelWrapper,
            css`
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            `,
            type === 'BUY'
              ? css`
                  background-color: rgb(14, 203, 129);
                `
              : css`
                  background-color: rgb(230, 232, 234);
                `,
          ]}
        >
          <span
            css={[
              cssLabel,
              type === 'BUY'
                ? css`
                    color: white;
                  `
                : css`
                    color: rgb(112, 122, 138);
                  `,
            ]}
          >
            BUY
          </span>
        </div>
      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        fill="none"
        css={[
          css`
            box-sizing: border-box;
            margin: 0px;
            min-width: 0px;
            color: rgb(14, 203, 129);
            font-size: 24px;
            width: 32px;
            height: 32px;
            background-color: rgb(230, 232, 234);
          `,
          type === 'BUY'
            ? css`
                fill: rgb(14, 203, 129);
                margin-left: -1px;
              `
            : css`
                fill: rgb(246, 70, 93);
                margin-right: -1px;
              `,
          type === 'BUY' && {
            transform: 'rotate(180deg)',
          },
        ]}
        // style="transform: rotate(180deg);"
      >
        <path d="M32 0L18.1569 0C17.096 0 16.0786 0.421427 15.3284 1.17157L3.32842 13.1716C1.76633 14.7337 1.76633 17.2663 3.32843 18.8284L15.3284 30.8284C16.0786 31.5786 17.096 32 18.1569 32H32V0Z" />
      </svg>

      <div onClick={handleClickSell} css={cssItemContainer}>
        <div
          css={[
            cssLabelWrapper,
            css`
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            `,
            type === 'SELL'
              ? css`
                  background-color: rgb(246, 70, 93);
                `
              : css`
                  background-color: rgb(230, 232, 234);
                `,
          ]}
        >
          <span
            css={[
              cssLabel,
              type === 'SELL'
                ? css`
                    color: white;
                  `
                : css`
                    color: rgb(112, 122, 138);
                  `,
            ]}
          >
            SELL
          </span>
        </div>
      </div>
    </div>
  )
}

export default TradeOrderTypeToggle
