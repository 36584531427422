/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { OrderCreatePayload } from '@app/reducers/markets/api'
import MarketDispatcher from '@app/reducers/markets/dispatcher'
import React, { useEffect } from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import { MarketFull, Order } from '@app/typings/core'
import TradeOrderFormButton from './components/OrderCreateButton/TradeOrderFormButton'
import Info from './elements/Info'
import OrderLimitForm from './forms/OrderLimitForm'
import OrderMarketForm from './forms/OrderMarketForm'
import useOrderFormValues from './hooks/useOrderFormValues'

interface Props {
  type: 'BUY' | 'SELL'
  price_type: 'LIMIT' | 'MARKET'
  market: MarketFull
  isButtonsVisible: boolean
}

function TradeOrderForm(props: Props) {
  const { market, isButtonsVisible, type, price_type } = props
  const form = useOrderFormValues({ price: market.price, price_type })
  const { price, total, quantity } = form

  useEffect(() => {
    form.resetForm()
  }, [price_type])

  const dispatch = useDispatch<ReduxDispatch>()
  const actionCreateOrder = useAsyncHandler(async (order_type: Order['order_type']) => {
    let orderCreatePayload: OrderCreatePayload = {
      quantity,
      order_type,
      price_type,
    }

    if (price_type === 'MARKET' && type === 'SELL') {
      orderCreatePayload.quantity = undefined
      orderCreatePayload.quantity_secondary = total
    }

    if (price_type === 'LIMIT') {
      orderCreatePayload.price = price
    }

    await dispatch(MarketDispatcher.createOrder(market.id, orderCreatePayload))
    form.resetForm()
  })

  return (
    <div>
      {price_type === 'MARKET' && <OrderMarketForm form={form} type={type} />}
      {price_type === 'LIMIT' && <OrderLimitForm form={form} type={type} />}

      <Info title={'Fee:'} value={'0%'} />

      {isButtonsVisible && (
        <TradeOrderFormButton
          type={type}
          onExecute={actionCreateOrder.execute}
          error={actionCreateOrder.error}
          quantity={quantity}
          total={total}
        />
      )}
    </div>
  )
}

export default TradeOrderForm
