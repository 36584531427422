import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import appTypes from '@app/reducers/app/types'
import { Order } from '../../typings/core'

interface State {
  listActive: ReduxStateOrderItem[]
  listActiveUpdatedAt?: StoreUpdatedAt

  listHistory: ReduxStateOrderItem[]
  listHistoryUpdatedAt?: StoreUpdatedAt
}

interface ReduxStateOrderItem extends Order {}

const initialState: State = {
  listActive: [],
  listHistory: [],
}

const reducerSlice = createSlice({
  name: 'ORDERS',
  initialState,
  extraReducers: (builder) => builder.addCase(appTypes.RESET, () => initialState),
  reducers: {
    setActiveOrders(state, action: PayloadAction<Order[]>) {
      const orders = action.payload
      state.listActive = orders
      state.listActiveUpdatedAt = Date.now()
    },

    setHistoryOrders(state, action: PayloadAction<Order[]>) {
      const orders = action.payload
      state.listHistory = orders
      state.listHistoryUpdatedAt = Date.now()
    },
  },
})

export const { actions, reducer } = reducerSlice
export default reducer
