import { DispatcherResponse } from '../../../typings/ReduxDispatch'
import actions from './actions'
import apiCurrencies from './api'

class Dispatcher {
  getList(): DispatcherResponse {
    return async (dispatch) => {
      const list = await apiCurrencies.getList()

      dispatch(actions.setList(list))
    }
  }
}

const CurrenciesDispatcher = new Dispatcher()
export default CurrenciesDispatcher
