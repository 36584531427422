import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import * as React from 'react'
import { StyledTableCell } from '../../../../../elements/table/TableBlack'

interface Props {
  children: React.ReactNode
}
export default function TransactionHistoryTable(props: Props) {
  const { children } = props

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table" stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">Address</StyledTableCell>
            <StyledTableCell align="center">Amount</StyledTableCell>

            {/*{!hideNavigationCell && <StyledTableCell align="left">Client</StyledTableCell>}*/}
            <StyledTableCell align="center">Status</StyledTableCell>

            <StyledTableCell align="right">Date</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  )
}
