import appTypes from '@app/reducers/app/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import invariant from 'invariant'
import _ from 'lodash'
import { WalletCurrency } from '../../typings/core'

interface State {
  [key: string]: ReduxStateWallet | undefined
}

export type ReduxStateWallet = WalletCurrency & {
  withdrawals: any[]
}

const initialState: State = {}

const slice = createSlice({
  name: 'WALLET',
  initialState,
  extraReducers: (builder) => builder.addCase(appTypes.RESET, () => initialState),
  reducers: {
    setItem(state, action: PayloadAction<WalletCurrency>) {
      const itemNext = action.payload
      state[itemNext.id] = { withdrawals: [], ...state[itemNext.id], ...itemNext }
    },
    setItems(state, action: PayloadAction<WalletCurrency[]>) {
      _.forEach(action.payload, (itemNext) => {
        state[itemNext.id] = { withdrawals: [], ...state[itemNext.id], ...itemNext }
      })
    },
    setWithdrawList(state, action: PayloadAction<{ id: string; list: ReduxStateWallet['withdrawals'] }>) {
      const { id, list } = action.payload

      let stateItem = state[id]
      invariant(stateItem, 'state item is not found')
      stateItem.withdrawals = list
    },
  },
})

export const { actions, reducer } = slice
// export const dashboardActions = actions
// export const dashboardReducer = reducer
export default reducer
