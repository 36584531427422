import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import appTypes from '../app/types'

interface State {
  token?: string | null
  remoteSessionId?: string | null
}

const initialState: State = {}

const slice = createSlice({
  name: 'AUTH',
  initialState,
  extraReducers: (builder) => builder.addCase(appTypes.RESET, () => initialState),
  reducers: {
    setToken(state, action: PayloadAction<string | null>) {
      state.token = action.payload
    },
    setRemoteSession(state, action: PayloadAction<string | null>) {
      state.remoteSessionId = action.payload
    },
  },
})

export const { reducer, actions } = slice
export default reducer
