/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React from 'react'

interface Props {
  currency: any
  className?: string
}
function CurrencyLogo(props: Props) {
  const { currency, className } = props
  const { logo, name } = currency

  return (
    <div>
      <img
        src={logo}
        title={name}
        css={css`
          width: 30px;
          height: 30px;
        `}
        className={className}
      />
    </div>
  )
}

export default CurrencyLogo
