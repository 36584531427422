/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React from 'react'
import { Currency } from '@app/typings/core'
import CoinInfoCompact from '../../assetTable/components/components/CoinInfoCompact'

interface Props {
  currency: Currency
  onSelect(currency: Currency): any
}
function CurrencyItem(props: Props) {
  const { currency, onSelect } = props
  const { name, symbol, logo } = currency

  const handleClick = () => onSelect(currency)

  return (
    <div
      onClick={handleClick}
      css={css`
        cursor: pointer;
        padding: 7px 30px;

        &:hover {
          background-color: #f1f1f1;
        }
      `}
    >
      <CoinInfoCompact currency={currency} />
    </div>
  )
}

export default CurrencyItem
