import _ from 'lodash'
import { DispatcherResponse } from '../../../typings/ReduxDispatch'
import { createLazy, TTL } from '../../libs/storeHelpers'
import MarketDispatcher from '../markets/dispatcher'
import apiOrders from './api'
import { actions } from './reducer'

class Dispatcher {
  getOrders(options: DispatcherOptions) {
    const { isForce } = options || {}
    return createLazy({
      isForce,
      timeout: TTL.MINUTE,
      onFindStoreUpdatedAt(state) {
        return state.orders.listActiveUpdatedAt
      },
      async onExecute(dispatch) {
        const orders = await apiOrders.getOrders()
        dispatch(actions.setActiveOrders(orders))
      },
    })
  }

  getHistoryOrders(options: DispatcherOptions) {
    const { isForce } = options || {}
    return createLazy({
      isForce,
      timeout: TTL.MINUTE,
      onFindStoreUpdatedAt(state) {
        return state.orders.listHistoryUpdatedAt
      },
      async onExecute(dispatch) {
        const orders = await apiOrders.getOrderHistory()
        dispatch(actions.setHistoryOrders(orders))
      },
    })
  }

  cancelOrder(order_id: string): DispatcherResponse {
    return async (dispatch, getState) => {
      const { listActive } = getState().orders
      const order = _.find(listActive, { id: order_id })

      await apiOrders.cancelOrder(order_id)
      dispatch(this.getOrders({ isForce: true }))

      if (order) {
        // refresh wallets, because frozen assets were released
        const { market_key } = order
        dispatch(MarketDispatcher.refreshUserDataRelatedToMarket({ market_key }))
      }
    }
  }
}

const OrderDispatcher = new Dispatcher()
export default OrderDispatcher
