/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import PriceWithIndicator from '../../../components/price/PriceWithIndicator'
import LinkTrade from '../../../elements/LinkTrade'
import { useKeyContext } from 'use-key-context'
import { Market } from '@app/typings/core'
import CurrencyLogo from '../components/CurrencyLogo'
import MarketChange from './components/MarketChange'
import { CellContainer } from './styled'

interface Props {
  market: Market
}

function MarketsTableRow(props: Props) {
  const { market } = props
  const { currency, key, price, currency_secondary } = market
  const { name, id, symbol } = currency || {}
  const { symbol: secondarySymbol } = currency_secondary || {}

  const showMoreKey = useKeyContext('show-more')

  return (
    <LinkTrade market_key={key} data-test-id={showMoreKey}>
      <Grid
        container
        css={css`
          &:hover {
            background-color: #fdfdfd;
          }
        `}
      >
        <Grid item>
          <CellContainer
            css={css`
              width: 400px;
            `}
          >
            <Grid container alignItems={'center'} flexWrap={'nowrap'}>
              <CurrencyLogo currency={currency} />
              <span
                css={css`
                  font-weight: bold;
                  margin-right: 5px;
                  margin-left: 10px;
                  min-width: 45px;
                  display: inline-block;
                  text-wrap: none;
                `}
              >
                {symbol}
              </span>
              <span
                css={css`
                  color: #a09e9e;
                `}
              >
                {name}
              </span>

              <Grid
                css={css`
                  padding-top: 6px;
                `}
              >
                {secondarySymbol ? (
                  <div>
                    <Typography
                      css={css`
                        margin-left: 7px;
                        font-size: 10px;
                        color: #a5a5bc;
                      `}
                    >
                      / {secondarySymbol}
                    </Typography>
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </CellContainer>
        </Grid>

        <Grid item>
          <CellContainer>
            <PriceWithIndicator price={price} showDollar />
          </CellContainer>
        </Grid>

        <Grid item>
          <CellContainer>
            <MarketChange market={market} />
          </CellContainer>
        </Grid>

        {/* <Grid item> */}
        {/*  <CellContainer> */}
        {/*    <span>-</span> */}
        {/*  </CellContainer> */}
        {/* </Grid> */}

        <Grid item />
      </Grid>
    </LinkTrade>
  )
}

export default MarketsTableRow
