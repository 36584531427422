/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import _ from 'lodash'
import React, { useMemo } from 'react'
import colors from '../../../../init/colors'
import { Market } from '@app/typings/core'

interface Props {
  market: Market
}

function MarketChange(props: Props) {
  const { market } = props
  const { price, change_24h, price_24h } = market

  let value = '-'
  let style: { color?: string } = {}

  let changeVal = useMemo(() => {
    if (!price_24h) return change_24h
    const diff = (Number(price) / Number(price_24h)) * 100 - 100
    return _.round(diff, 2)
  }, [price])

  if (changeVal) {
    value = `${changeVal}%`

    if (changeVal > 0) {
      value = `+${changeVal}%`
      style.color = colors.upColor
    } else if (changeVal < 0) {
      value = `${changeVal}%`
      style.color = colors.downColor
    }
  }
  return (
    <Typography fontWeight={'bold'} style={style}>
      {value}
    </Typography>
  )
}

export default MarketChange
