import appTypes from '@reducers/app/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from '@app/typings/core'

interface State extends User {}

const initialState: State = {} as any

const slice = createSlice({
  name: 'ME',
  initialState,
  extraReducers: {
    [appTypes.RESET]: () => initialState,
  },
  reducers: {
    set(state, action: PayloadAction<User>) {
      return { ...state, ...action.payload }
    },
  },
})

export const { reducer, actions } = slice
export default reducer
