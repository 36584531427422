/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Divider, Grid } from '@mui/material'
import React from 'react'
import { MarketCandleInterval } from '@app/typings/core'

export const HEIGHT = 33

const timeDefaultOptions: { label: string; value: MarketCandleInterval }[] = [
  { label: '5m', value: '5m' },
  { label: '15m', value: '15m' },
  { label: '30m', value: '30m' },
  { label: '1h', value: '1h' },
  { label: '4h', value: '4h' },
]

interface Props {
  scaling: number // in minutes
  onChange(scalingMinutes: number): any
}

function ChartTimeScaling(props: Props) {
  const { scaling, onChange } = props

  return (
    <div style={{ height: HEIGHT }}>
      <Divider />

      <Grid
        container
        css={css`
          margin-top: 5px;
          margin-bottom: 10px;
        `}
      >
        {timeDefaultOptions.map((item) => (
          <div
            key={`trade-interval-${item.value}`}
            onClick={() => onChange(item.value)}
            css={css`
              margin-left: 10px;
              cursor: pointer;
            `}
          >
            <span
              css={[
                css`
                  color: rgb(112, 122, 138);
                  font-size: 12px;
                `,
                scaling === item.value &&
                  css`
                    color: rgb(201, 148, 0);
                  `,
              ]}
            >
              {item.label}
            </span>
          </div>
        ))}
      </Grid>

      <Divider />
    </div>
  )
}

export default ChartTimeScaling
