import keyManager from 'constant-manager'

const tokenErrorCodes = keyManager(
  {
    notFound: 'not-found',
    notFoundHotWallet: 'no-hot-wallet',
    noWithdrawalInfo: 'no-withdrawal-info',
    noLiquidity: 'no-liquidity',
  },
  {
    prefix: 'error/token/',
  },
)

export default tokenErrorCodes
