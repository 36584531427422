/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import React from 'react'
import PriceWithIndicator from '../../../components/price/PriceWithIndicator'
import { Currency, Market } from '@app/typings/core'
import CurrencyLogo from '../../markets/components/CurrencyLogo'
import MarketChange from '../../markets/table/components/MarketChange'

interface Props {
  market: Market
  currencySecondary: Currency
}
function TradeHead(props: Props) {
  const { market, currencySecondary } = props
  const { currency, price } = market
  const { name, symbol } = currency as Currency

  const { symbol: symbol_secondary } = currencySecondary || {}

  return (
    <div
      css={css`
        padding-bottom: 10px;
        padding-top: 10px;
        padding-left: 30px;
      `}
    >
      <Grid container alignItems={'center'}>
        <div>
          <Grid container>
            <CurrencyLogo
              currency={currency}
              css={css`
                width: 50px;
                height: 50px;
                margin-right: 10px;
              `}
            />
            <div>
              <Typography
                css={css`
                  font-size: 24px;
                  line-height: 26px;
                  font-weight: bold;
                `}
              >
                {symbol} / {symbol_secondary}
              </Typography>
              <Typography
                component={'h1'}
                css={css`
                  font-size: 16px;
                  font-weight: normal;
                `}
              >
                {name}
              </Typography>
            </div>
          </Grid>
        </div>

        <div>
          <span>
            <PriceWithIndicator
              price={price}
              showDollar
              css={css`
                color: #11ad7a;
                font-size: 24px;
                margin-left: 30px;
                display: inline-block;
              `}
            />
          </span>
        </div>

        <div
          css={css`
            width: 1px;
            height: 40px;
            background-color: #d9dfea;
            margin: 0 20px;
          `}
        />

        <div
          css={css`
            //margin-left: 10px;
          `}
        >
          <Typography
            css={css`
              font-size: 14px;
              line-height: 20px;
              color: #9c9ca1;
            `}
          >
            24h Change
          </Typography>
          <MarketChange market={market} />
        </div>
      </Grid>
    </div>
  )
}

export default TradeHead
