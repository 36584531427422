import apiClient from '@app/api/apiClient'
import { MarketFull } from '@typings/core'
import { MarketCandle, MarketCandleInterval, Order, WalletCurrency } from '../../typings/core'

async function get(params: { id: string } | { market_key: string }): Promise<MarketFull> {
  const { data } = await apiClient.get(`/market/${params.id || params.market_key}`)
  return data
}
async function getList(params: { search: string }): Promise<{ list: any[] }> {
  const { data } = await apiClient.get('/market/list', { params })
  return data
}

async function lookupMarket(key: string): Promise<any> {
  const { data } = await apiClient.get('/market/lookup', {
    params: {
      key,
    },
  })
  return data
}

async function getOrders(market_id: string): Promise<Order[]> {
  const { data } = await apiClient.get(`/market/${market_id}/orders`)
  return data
}

async function getOrderBook(market_id: string): Promise<any> {
  const { data } = await apiClient.get(`/market/${market_id}/order-book`)
  return data
}

export type OrderCreatePayload = Pick<Order, 'order_type' | 'price_type'> &
  Partial<Pick<Order, 'quantity' | 'quantity_secondary'>> & { price?: number | string | null }
async function createOrder(market_id: string, body: OrderCreatePayload): Promise<any> {
  const { data } = await apiClient.post(`/market/${market_id}/order`, body)
  return data
}

async function getCandles(
  market_id: string,
  params?: {
    startTime?: number
    endTime?: number
    interval: MarketCandleInterval
  },
): Promise<MarketCandle[]> {
  const { data } = await apiClient.get(`/market/${market_id}/candles`, {
    params,
  })

  return data
}

async function getWallets(market_id: string): Promise<WalletCurrency[]> {
  const { data } = await apiClient.get(`/market/${market_id}/wallets`)

  return data
}

export default {
  get,
  getList,
  getOrders,
  getCandles,
  getOrderBook,
  getWallets,
  createOrder,
  lookupMarket,
}
