// import MeDispatcher from '../me/dispatcher'
import MeDispatcher from '@app/reducers/me/dispatcher'
import { DispatcherResponse } from '../../typings/ReduxDispatch'

class Dispatcher {
  install() {
    return async (dispatch) => {
      // todo looks like it called many times
      // await dispatch(MeDispatcher.getMySelf())
      // await dispatch(CompanyDispatcher.getCompany())

      dispatch(this.installLazy())
    }
  }

  installLazy(): DispatcherResponse {
    return async (dispatch, getState) => {
      const { token } = getState().auth
      if (!token) return null
      await dispatch(MeDispatcher.getMe())
    }
  }
}

const AppDispatcher = new Dispatcher()
export default AppDispatcher
