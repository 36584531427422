/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import { ReduxState } from '@reducers/index'
import MeDispatcher from '@reducers/me/dispatcher'
import React, { useEffect } from 'react'
import { useAsyncIntervalFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Loader from '../../components/Loader'
import AppBar from '../../components/appBar/AppBar'
import { ReduxDispatch } from '../../typings/ReduxDispatch'

function SignupWaitForUser(props: {}) {
  const navigate = useNavigate()
  const dispatch = useDispatch<ReduxDispatch>()

  const { me } = useSelector((state: ReduxState) => ({
    me: state.me,
  }))

  useAsyncIntervalFetch(async () => {
    await dispatch(MeDispatcher.getMe())
  }, 5000)

  const handleLoginSuccess = () => {
    navigate('/wallet') // or manual link?
  }

  useEffect(() => {
    if (me.id) {
      handleLoginSuccess()
    }
  }, [me.id])

  return (
    <div
      css={css`
        background-color: #e2e2e2;
        flex-grow: 1;
      `}
    >
      <AppBar />

      <Grid container justifyContent={'center'}>
        <Grid item md={8}>
          <Typography
            variant={'h1'}
            css={css`
              margin-top: 20px;
              margin-bottom: 20px;
            `}
          >
            Completing signup.
          </Typography>

          <div
            css={css`
              background-color: #fff;
              padding: 20px;
              border-radius: 20px;
              box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
            `}
          >
            <Grid container alignItems={'center'}>
              <Loader
                css={css`
                  margin-right: 20px;
                  margin-top: -3px;
                `}
              />

              <Typography>We are preparing your user right now.</Typography>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default SignupWaitForUser
