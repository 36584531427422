/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import bscIcon from '../../assets/chains/bsc.svg'
import ethIcon from '../../assets/chains/eth.svg'
import polygonIcon from '../../assets/chains/polygon.svg'

interface Props {
  title: string
  iconUrl: string
  className?: string
  size?: 'small'
}
function HomeChainItem(props: Props) {
  const { title, iconUrl, className, size } = props

  return (
    <div
      css={css`
        margin-bottom: -15px;
      `}
    >
      <img
        src={iconUrl}
        css={[
          css`
            width: 200px;
            height: 200px;
            margin: 0 10px;
            object-fit: contain;
          `,
          size &&
            css`
              width: 90px;
              height: 90px;
            `,
        ]}
        alt={''}
        className={className}
      />
      {/* <div> */}
      {/*   <Typography color={'white'}>Ethereum</Typography> */}
      {/* </div> */}
    </div>
  )
}

export default HomeChainItem
