/** @jsx jsx */
import apiWalletClient from '@app/libs/api-wallet-client/api'
import { jsx, css } from '@emotion/react'
import { Grid } from '@mui/material'
import _ from 'lodash'
import React, { useState } from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { FadeLoader } from 'react-spinners'
import useModalState from '../../../hooks/useModalState'
import { Currency } from '@app/typings/core'
import MockedInput from '../deposit/components/MockedInput'
import ModalChainSelect from '../modals/ModalChainSelect'
import ModalCurrencySelect from '../modals/ModalCurrencySelect'
import WithdrawForm from './WithdrawForm'

interface Props {
  currency: Currency
  onSelect(currency: Currency)
}

function WithdrawFormCurrency(props: Props) {
  const { currency, onSelect } = props
  const [chain_id, setChainId] = useState<string | null>(null)
  const { id: currency_id, name, symbol, logo } = currency

  const modalCoinSelect = useModalState(false)
  const modalChainSelect = useModalState(false)

  const fetchChains = useAsyncFetch(async () => {
    const chains = await apiWalletClient.currency.getChains({ currency_id })

    // don't select default chain for currency
    // if (!chain_id) {
    //   const chainDefault = chains[0]
    //   if (chainDefault) {
    //     console.log('select default chain:', chainDefault)
    //     setChainId(chainDefault.id)
    //   }
    // }

    return chains
  }, [currency_id])

  const handleCurrencySelect = (currency: Currency) => {
    setChainId(null)
    onSelect(currency)
  }

  const handleChainSelect = (chain) => {
    setChainId(chain.id)
  }

  return (
    <div>
      <ModalCurrencySelect {...modalCoinSelect} onSelect={handleCurrencySelect} title={'Select crypto to withdraw'} />
      <ModalChainSelect
        {...modalChainSelect}
        chains={fetchChains.data}
        onSelect={handleChainSelect}
        type={'withdraw'}
        currency={currency}
      />

      <MockedInput name={name} short_name={symbol} onClick={modalCoinSelect.onOpen} logo={logo} />

      <RendererStatusSplit
        statuses={fetchChains}
        renderEmpty={() => null}
        renderLoading={() => (
          <Grid container justifyContent={'center'}>
            <FadeLoader />
          </Grid>
        )}
        render={(chains) => {
          // console.log('renderer', data)
          const chain = _.find(chains, (item) => item.id === chain_id)
          // if (!chain) return null

          return (
            <div>
              <WithdrawForm currency={currency} chain={chain} onChainClick={modalChainSelect.onOpen} />
            </div>
          )
        }}
      />
    </div>
  )
}

export default WithdrawFormCurrency
