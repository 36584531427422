import apiClient from '@app/api/apiClient'
import { Order } from '../../typings/core'

async function getOrders(): Promise<Order[]> {
  const { data } = await apiClient.get(`/order/list`)
  return data
}

async function getOrderHistory(): Promise<Order[]> {
  const { data } = await apiClient.get(`/order/history`)
  return data
}

async function cancelOrder(order_id: string): Promise<Order[]> {
  const { data } = await apiClient.post(`/order/${order_id}/cancel`)
  return data
}

export default {
  getOrders,
  getOrderHistory,
  cancelOrder,
}
