/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import React from 'react'
import AppBar from '../../../components/appBar/AppBar'
import config from '../../../config'
import bgImage2 from '../assets/fabio-oyXis2kALVg-unsplash.jpg'
import googlePlayBadge from '../assets/google-play-badge.png'
import HomeMarkets from './HomeMarkets'

function HomeHead() {
  return (
    <Grid
      container
      flexDirection={'column'}
      css={css`
        background-image: url('${bgImage2}');
        background-position: center;
        background-size: cover;
        height: 100vh;
        min-height: 500px;
        flex-wrap: nowrap;
      `}
    >
      <AppBar />

      <Grid
        container
        flexDirection={'column'}
        justifyContent={'center'}
        css={css`
          width: 100%;
          padding-left: 10%;
          padding-bottom: 5%;
          flex-grow: 1;
        `}
      >
        <Grid
          item
          css={css`
            margin-bottom: 50px;
          `}
        >
          <Typography
            variant={'h1'}
            css={css`
              color: #fff;
              margin-bottom: 10px;
            `}
          >
            Octo Ex
          </Typography>
          <Typography
            variant={'h2'}
            css={css`
              color: #fff;
            `}
          >
            Buy and sell crypto in minutes
          </Typography>
        </Grid>

        <HomeMarkets />

        <div
          css={css`
            margin-top: 40px;
          `}
        >
          <a href={config.links.googlePlayStore} target={'_blank'} rel="noreferrer">
            <img
              src={googlePlayBadge}
              alt={'Google Play Link'}
              data-s={'646 × 250'}
              css={css`
                width: 200px;
                height: 70px;
                object-fit: contain;
                margin-left: -20px; // img have empty spaces =(
              `}
            />
          </a>
        </div>
      </Grid>
    </Grid>
  )
}

export default HomeHead
