/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import React, { ReactElement } from 'react'

interface Props {
  icon: ReactElement
  children: ReactElement | string
  className?: string
  isSelected?: boolean
  onClick?(): any
}

function ProfileTab(props: Props) {
  const { icon, children, className, isSelected, onClick } = props

  return (
    <Grid
      container
      alignItems={'center'}
      className={className}
      css={css`
        padding: 15px 10px 15px 15px;
        cursor: pointer;
        position: relative;
      `}
      onClick={onClick}
    >
      {isSelected ? (
        <div
          css={css`
            width: 4px;
            position: absolute;
            bottom: 0;
            top: 0;
            left: 0;
            background-color: #5c94c3;
          `}
        />
      ) : null}

      <div
        css={css`
          width: 30px;
        `}
      >
        {icon}
      </div>

      <Typography>{children}</Typography>
    </Grid>
  )
}

export default ProfileTab
