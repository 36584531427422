/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid } from '@mui/material'
import CurrencyDispatcher from '@app/reducers/currencies/dispatcher'
import { ReduxState } from '@reducers/index'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import Loader from '../../components/Loader'
import AppBar from '../../components/appBar/AppBar'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import { Currency } from '@app/typings/core'
import WalletBreadcrumbs from './components/WalletBreadcrumbs'
import useSelectedCurrency from './hooks/useSelectedCurrency'
import WithdrawFormCurrency from './withdraw/WithdrawFormCurrency'
import WithdrawHistory from './withdraw/transactionHistory/WithdrawHistory'

function WalletWithdraw() {
  const dispatch = useDispatch<ReduxDispatch>()

  const fetchAction = useAsyncFetch(async () => {
    await dispatch(CurrencyDispatcher.getList())
  })

  const [itemSelected, setSelectedCurrencyId] = useSelectedCurrency()

  const { wallet } = useSelector((state: ReduxState) => ({
    wallet: itemSelected?.id ? state.wallet[itemSelected.id] : null,
  }))

  const handleCurrencySelect = (currency: Currency) => {
    setSelectedCurrencyId(currency.id)
  }

  return (
    <div
      css={css`
        min-height: 700px;
        padding-bottom: 50px;
      `}
    >
      <AppBar />

      <Grid container justifyContent={'center'}>
        <div
          css={css`
            max-width: 1000px;
            width: 100%;
            padding-top: 20px;
          `}
        >
          <WalletBreadcrumbs title={'Withdraw'} />

          <div
            css={css`
              margin-top: 50px;
            `}
          >
            <RendererStatusSplit
              statuses={fetchAction}
              isEmpty={!itemSelected}
              renderLoading={() => (
                <Grid container justifyContent={'center'}>
                  <Loader />
                </Grid>
              )}
              renderEmpty={() => <div>Not found</div>}
              render={() => (
                <Grid container justifyContent={'center'}>
                  <Grid item md={6}>
                    {itemSelected && <WithdrawFormCurrency currency={itemSelected} onSelect={handleCurrencySelect} />}
                  </Grid>

                  <Grid item md={12}>
                    {wallet && <WithdrawHistory wallet={wallet} />}
                  </Grid>
                </Grid>
              )}
            />
          </div>
        </div>
      </Grid>
    </div>
  )
}

export default WalletWithdraw
