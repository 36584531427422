/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid } from '@mui/material'
import React from 'react'
import PriceTypeItem from './PriceTypeItem'

interface Props {
  type: 'MARKET' | 'LIMIT'
  onChange(type: 'MARKET' | 'LIMIT'): any
}

function PriceTypeToggle(props: Props) {
  const { type, onChange } = props

  return (
    <div
      css={css`
        margin-bottom: 10px;
      `}
    >
      <Grid container>
        <PriceTypeItem value={'LIMIT'} title={'LIMIT'} onChange={onChange} isSelected={type === 'LIMIT'} />
        <PriceTypeItem value={'MARKET'} title={'MARKET'} onChange={onChange} isSelected={type === 'MARKET'} />
      </Grid>
    </div>
  )
}

export default PriceTypeToggle
