/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'

const cssCell = css`
  font-size: 12px;
  color: rgb(112, 122, 138);
`

function WalletAssetsTable() {
  return (
    <TableHead>
      <TableRow>
        <TableCell css={cssCell}>Coin</TableCell>
        <TableCell css={cssCell}>Total</TableCell>
        <TableCell css={cssCell}>Available</TableCell>
        <TableCell css={cssCell}>In Order</TableCell>
        <TableCell css={cssCell}>BTC Value</TableCell>
        <TableCell css={cssCell}>Actions</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default WalletAssetsTable
