// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAad7nStTkodi5QTO_lJTaksJ3Mg_FS_DM',
  authDomain: 'octoex.firebaseapp.com',
  projectId: 'octoex',
  storageBucket: 'octoex.appspot.com',
  messagingSenderId: '260498582289',
  appId: '1:260498582289:web:4b26d9da6693b4d3943ab0',
  measurementId: 'G-Y1JWS6B2DH',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
