import { useMemo } from 'react'

function useLimitedArray<ItemType>(items: ItemType[] | undefined, length: number): ItemType[] {
  return useMemo(() => {
    if (!items) return [] as ItemType[]
    if (items.length <= length) return items
    return items.slice(0, length)
  }, [items])
}

export default useLimitedArray
