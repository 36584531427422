import React from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import AppContext from './AppContext'
import Routes from './Router'
import SocketProvider from '@app/socket/SocketProvider'
import AppLauncher from './components/AppLauncher'
import { BugsnagErrorBoundary } from './libs/bugSnag'
import store, { persistor } from '@reducers/store'

import './libs/firebase/firebase'
import './initClients'

export default function () {
  return (
    <BugsnagErrorBoundary>
      <AppContext>
        <PersistGate loading={null} persistor={persistor}>
          <SocketProvider />
          <AppLauncher>
            {/* <StatusListeners /> */}
            {/* <CssBaseline /> */}

            <Routes />
          </AppLauncher>
        </PersistGate>
      </AppContext>
    </BugsnagErrorBoundary>
  )
}
