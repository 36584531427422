/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import { ReduxState } from '@reducers/index'
import MeDispatcher from '@reducers/me/dispatcher'
import React from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/Loader'
import RendererActionSplit from '../../../libs/RendererActionSplit'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'

function EmailVerifyToolbar() {
  const dispatch = useDispatch<ReduxDispatch>()
  const { me } = useSelector((state: ReduxState) => ({
    me: state.me,
  }))

  const actionSendVerification = useAsyncHandler(async () => {
    const result = await emailApi.sendVerification()
    if (result.is_verified) {
      await dispatch(MeDispatcher.getMe())
    }
  })

  if (!me.id || me.is_email_verified) {
    // make sure component will not blink when me is not fetched
    return null
  }

  return (
    <div
      css={css`
        margin-bottom: 10px;
      `}
    >
      <div
        css={css`
          background-color: #f2c4af;
          border-radius: 10px;
          padding: 5px 20px;
        `}
      >
        <Grid container alignItems={'center'}>
          <Grid flexGrow={1}>
            <Typography fontWeight={'bold'}>Please verify your email</Typography>
            <Typography
              css={css`
                font-size: 14px;
              `}
            >
              For security reasons you must prove you have access to your email. It is required for some key actions
              like withdraw.
            </Typography>
          </Grid>

          <Grid>
            <div>
              <RendererActionSplit
                statuses={actionSendVerification}
                renderSuccess={() => (
                  <Typography
                    css={css`
                      font-size: 14px;
                    `}
                  >
                    Email is sent
                  </Typography>
                )}
                renderError={(error) => (
                  <Typography
                    color={'error'}
                    css={css`
                      font-size: 14px;
                    `}
                  >
                    {error}
                  </Typography>
                )}
                renderLoading={() => <Loader scale={0.3} />}
                render={() => (
                  <Typography
                    onClick={actionSendVerification.execute}
                    css={css`
                      font-size: 16px;
                      color: #0043ff;
                      font-weight: bold;
                      cursor: pointer;
                    `}
                  >
                    Verify
                  </Typography>
                )}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default EmailVerifyToolbar
