import _ from 'lodash'
import { DispatcherResponse } from '../../../typings/ReduxDispatch'
import apiWalletClient from '../../libs/api-wallet-client/api'
import { actions as chainActions } from '../chains/reducer'
import apiCurrencies from './api'
import { actions } from './reducer'

class Dispatcher {
  getItem(currency_id: string, options?: DispatcherOptions): DispatcherResponse {
    const { isForce } = options || {}

    return async (dispatch, getState) => {
      const currencyExists = getState().currencyBucket[currency_id]
      if (!isForce && currencyExists) return null

      const item = await apiCurrencies.getById(currency_id)
      if (!item) return null
      dispatch(actions.setItem(item))
    }
  }

  getChains({ currency_id }: { currency_id: string }) {
    return async (dispatch) => {
      const list = await apiWalletClient.currency.getChains({ currency_id })
      _.map(list, (chain) => dispatch(chainActions.set(chain)))
      dispatch(actions.setChains({ id: currency_id, list }))
    }
  }
}

const CurrencyDispatcher = new Dispatcher()
export default CurrencyDispatcher
