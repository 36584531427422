/** @jsx jsx */
import MarketDispatcher from '@app/reducers/markets/dispatcher'
import { jsx, css } from '@emotion/react'
import { Divider, Grid } from '@mui/material'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import React, { useState } from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { KeyContextProvider, useKeyContext } from 'use-key-context'
import Loader from '../../../components/Loader'
import BootstrapInput from '../../../elements/form/BootstrapInput'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import MarketsTableHead from './MarketsTableHead'
import MarketsTableRow from './MarketsTableRow'

function MarketsTable() {
  const dispatch = useDispatch<ReduxDispatch>()
  const [search, setSearch] = useState('')

  const { markets } = useSelector((state: ReduxState) => ({
    markets: state.lists.markets,
  }))

  const actionFetch = useAsyncFetch(async () => {
    await dispatch(MarketDispatcher.getList({ search }))
    // await dispatch(CurrencyDispatcher.getList())
  }, [search])

  console.log('MarketsTable render', markets)

  const key = useKeyContext('')

  return (
    <div>
      <div
        css={css`
          margin-bottom: 10px;
        `}
      >
        <BootstrapInput
          value={search}
          placeholder={'Search'}
          onChange={(e) => setSearch(e.target.value || '')}
          css={css`
            min-width: 300px;
          `}
        />
      </div>

      <MarketsTableHead />

      <RendererStatusSplit
        statuses={actionFetch}
        isEmpty={_.isEmpty(markets)}
        renderLoading={() => (
          <Grid container justifyContent={'center'}>
            <Loader />
          </Grid>
        )}
        render={() =>
          _.map(markets, (item, index) => (
            <KeyContextProvider key={`${key}/${item.id}`} value={index}>
              <div>
                {index !== 0 && <Divider />}
                <MarketsTableRow market={item} />
              </div>
            </KeyContextProvider>
          ))
        }
      />
    </div>
  )
}

export default MarketsTable
