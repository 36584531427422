import { Typography } from '@mui/material'
import React, { useMemo } from 'react'

interface Props {
  value: number | string
  length?: number
}
function CurlyNumber(props: Props) {
  const { value, length = 9 } = props

  const formatted = useMemo(() => {
    const n = Number(value)
    const nStr = n.toString()
    const isFloating = n % 1 !== 0

    let txt = nStr
    if (!isFloating) txt += '.'

    if(n > 1) {
      const numInt = Math.floor(n)
      const numIntLength = `${numInt}`.length
      const floatingLength = length - numIntLength - 1
      if(floatingLength > 0) txt = n.toFixed(floatingLength)
    }

    while (txt.length < length) {
      txt += '0'
    }

    return txt
  }, [])

  return <Typography>{formatted}</Typography>
}

export default CurlyNumber
