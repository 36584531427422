import { ReduxSharedState } from '@app/reducers'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import socket from '../socket'

function useSocketAuthorization() {
  const loginRef = useRef(false)
  const { token } = useSelector((state: ReduxSharedState) => ({
    token: state.auth.token,
  }))

  useEffect(() => {
    if (token) {
      socket.emit('USER/LOGIN', token)
    } else {
      socket.emit('USER/LOGOUT')
    }
  }, [token])
}

export default useSocketAuthorization
