import { applyAxiosLogger } from '@zvs001/axios-logger'
import axios from 'axios'
import { applyEncryptToAxios } from 'axios-encrypt'
import CryptoJS from 'crypto-js'
import config from '../config'

// configs must be set later
const instance = axios.create()

applyEncryptToAxios(instance, {
  onEncrypt(str: string): string {
    let encrypted = CryptoJS.DES.encrypt(str, config.apiEncKey)
    return encrypted.toString()
  },
})

if (process.env.NODE_ENV !== 'production') {
  applyAxiosLogger(instance, {
    logVariant: 'errors',
  })
}

export default instance
