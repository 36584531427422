import { TableBody, TableContainer, Table } from '@mui/material'
import _ from 'lodash'
import React from 'react'
import { Order } from '@app/typings/core'
import OrderListItem from './OrderListItem'
import OrderListTableHead from './OrderListTableHead'

interface Props {
  list: Order[]
}

function OrderList(props: Props) {
  const { list } = props

  return (
    <TableContainer>
      <Table aria-label="list of orders" size={'small'}>
        <OrderListTableHead />
        <TableBody>
          {_.map(list, (order) => (
            <OrderListItem order={order} key={`order-item-${order.id}`} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default OrderList
