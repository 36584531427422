/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import React from 'react'

function OrDivider(props: {}) {
  return (
    <Grid
      container
      justifyContent={'center'}
      css={css`
        position: relative;
        margin: 10px 0;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          height: 1px;
          left: 0;
          right: 0;
          background-color: #ccc;
          z-index: -1;
        }
      `}
    >
      <Typography
        css={css`
          padding: 0 5px;
          background-color: #fff;
          display: inline-block;
          font-size: 12px;
          line-height: 18px;
        `}
      >
        or
      </Typography>
    </Grid>
  )
}

export default OrDivider
