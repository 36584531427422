/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import React, { ReactElement } from 'react'

// export const CellContainer = styled.div`
//   padding: 10px 20px;
//   width: 200px;
// `

export const CellContainer = ({ children, className }: { children: ReactElement; className?: string }) => (
  <Grid
    container
    alignItems={'center'}
    className={className}
    css={css`
      padding: 10px 20px;
      width: 200px;
      height: 100%;
    `}
  >
    {children}
  </Grid>
)
