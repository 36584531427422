import React, { ReactElement } from 'react'
import { ErrorMessageProvider } from 'react-hooks-use-error'
import apiErrorUtil from './errors/apiErrorUtil'

export interface Props {
  children: ReactElement
}

export default function ErrorMessageProviderWrapper(props: Props) {
  const { children } = props

  const getErrorMessage = (e) => {
    // const { status } = apiErrorUtil.getRequestInfo(e)
    // const errorCode = apiErrorUtil.getErrorCodeFromApi(e)
    // console.log('getErrorMessage:', status, errorCode)
    const errorMessage = apiErrorUtil.getFormattedApiError(e)

    return errorMessage
  }

  return <ErrorMessageProvider getErrorMessage={getErrorMessage}>{children}</ErrorMessageProvider>
}
