/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Button, ButtonProps } from '@mui/material'
import React from 'react'
import { MarketFull } from '@app/typings/core'

const OrderStyledButton = (props: ButtonProps) => (
  <Button
    variant={'contained'}
    color={'primary'}
    fullWidth
    css={css`
      margin-top: 10px;
      color: #fff;
      font-weight: bold;
    `}
    {...props}
  />
)

interface Props {
  type: 'BUY' | 'SELL'
  market: MarketFull
  onClickSell(): any
  onClickBuy(): any
  disabled?: boolean
}

function StyledOrderFormButton(props: Props) {
  const { market, type, onClickSell, onClickBuy, disabled } = props
  const { currency } = market
  const { symbol } = currency

  if (type === 'BUY') {
    return (
      <OrderStyledButton
        onClick={onClickBuy}
        disabled={disabled}
        css={css`
          background-color: rgb(14, 203, 129);
          &:hover {
            background-color: rgb(50, 217, 147);
          }
        `}
      >
        BUY {symbol}
      </OrderStyledButton>
    )
  }

  if (type === 'SELL') {
    return (
      <OrderStyledButton
        onClick={onClickSell}
        disabled={disabled}
        css={css`
          background-color: rgb(246, 70, 93);
          &:hover {
            background-color: rgb(255, 112, 126);
          }
        `}
      >
        SELL {symbol}
      </OrderStyledButton>
    )
  }

  return null
}

export default StyledOrderFormButton
