/** @jsx jsx */
import useOrderBookArrays from '@app/hooks/view/useOrderBookArrays'
import { ReduxStateMarketsItem } from '@app/reducers/markets/reducer'
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import _ from 'lodash'
import React, { useMemo } from 'react'
import PriceWithIndicator from '../../../components/price/PriceWithIndicator'
import OrderBookHead from './book/OrderBookHead'
import OrderBookRow from './book/OrderBookRow'

interface Props {
  market: ReduxStateMarketsItem
}
function TradeOrderBook(props: Props) {
  const { market } = props
  const { price, orderBook } = market

  const { group_key } = orderBook || {}
  const bookArrays = useOrderBookArrays({ market })
  const { listUp, listDown, quantity_max } = bookArrays

  const listUpReversed = useMemo(() => listUp.reverse(), [listUp])

  return (
    <div
      css={css`
        padding: 5px;
      `}
    >
      <Grid
        container
        justifyContent={'space-between'}
        css={css`
          margin-bottom: 10px;
        `}
      >
        <Typography variant={'h2'}>Order book</Typography>

        <Typography
          css={css`
            font-size: 12px;
            margin-right: 20px;
            margin-top: 10px;
          `}
        >
          step: {group_key}
        </Typography>
      </Grid>

      <OrderBookHead />
      {_.map(listUpReversed, (item) => (
        <OrderBookRow item={item} quantity_max={quantity_max} type={'SELL'} key={`order-book-up-${item.price}`} />
      ))}
      <div
        css={css`
          padding: 10px 0;
        `}
      >
        <PriceWithIndicator
          showArrows
          price={price}
          css={css`
            font-weight: bold;
            font-size: 20px;
          `}
        />
      </div>
      {_.map(listDown, (item) => (
        <OrderBookRow item={item} quantity_max={quantity_max} type={'BUY'} key={`order-book-down-${item.price}`} />
      ))}
    </div>
  )
}

export default TradeOrderBook
