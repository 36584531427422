/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import LinkTrade from '../../../../../elements/LinkTrade'
import { Currency } from '@app/typings/core'
import CurrencyLogo from '../../../../markets/components/CurrencyLogo'

interface Props {
  currency: Currency
}
function CoinInfoCompact(props: Props) {
  const { currency } = props
  const { name, symbol, market_key } = currency

  return (
    <Grid container alignItems={'center'}>
      <CurrencyLogo currency={currency} />
      <div
        css={css`
          padding-left: 7px;
        `}
      >
        <Typography
          css={css`
            font-weight: bold;
            display: inline-block;
          `}
        >
          {symbol}
        </Typography>

        {/* <LinkTrade market_key={market_key}> */}
        <Typography
          css={css`
            color: #a09e9e;
            font-size: 12px;
          `}
        >
          {name}
        </Typography>
        {/* </LinkTrade> */}
      </div>
    </Grid>
  )
}

export default CoinInfoCompact
