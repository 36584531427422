import BN from 'big.js'
import { useState } from 'react'
import { Order } from '@app/typings/core'

function getInputNormalized(e): string {
  const value = e.target.value || ''
  return value.replace(/[^\d.]/, '')
}

export type OrderFormValues = ReturnType<typeof useOrderFormValues>

function useOrderFormValues(params: { price?: string; price_type: Order['price_type'] }) {
  const { price_type } = params
  const [price, setPrice] = useState<string>(params.price || '')
  const [quantity, setQuantity] = useState<string>('')
  const [total, setTotal] = useState<string>('')
  const [leadingValue, setLeadingValue] = useState<'quantity' | 'total'>('quantity')

  const refreshTotal = ({ price, amount }: { price: string; amount: string }) => {
    if (!price || !amount) return null
    const amountBN = new BN(amount)
    const priceBN = new BN(price)

    const totalBN = amountBN.mul(priceBN)
    const totalStr = totalBN.toString()
    if (total !== totalStr) setTotal(totalStr)
  }
  const refreshAmount = ({ price, total }: { price: string; total: string }) => {
    if (!price || !total) return null
    const totalBN = new BN(total || 0)
    const priceBN = new BN(price || 0)

    const amountBN = totalBN.div(priceBN).round(5)
    const amountStr = amountBN.toString()
    if (total !== amountStr) setQuantity(amountStr)
  }

  const onPriceChange = (e) => {
    // only received data from input
    const val = getInputNormalized(e)
    setPrice(val)
    refreshTotal({ price: val, amount: quantity })
  }

  const onQuantityChange = (e) => {
    // only received data from input

    const val = getInputNormalized(e)
    setQuantity(val)
    refreshTotal({ price, amount: val })
    if (leadingValue !== 'quantity') setLeadingValue('quantity')
  }

  const onTotalChange = (e) => {
    const val = getInputNormalized(e)
    setTotal(val)
    refreshAmount({ price, total: val })
    if (leadingValue !== 'total') setLeadingValue('total')
  }

  function resetForm() {
    setQuantity('')
    setTotal('')
    setPrice(params.price || '')
    setLeadingValue('quantity')
  }

  return {
    price,
    quantity,
    total,
    leadingValue,
    onPriceChange,
    onTotalChange,
    onQuantityChange,
    resetForm,
  }
}

export default useOrderFormValues
