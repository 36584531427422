/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Typography } from '@mui/material'
import { QRCodeSVG } from 'qrcode.react'
import React from 'react'
import useClipboard from 'react-use-clipboard'

interface Props {
  chainAddress: any
}

function DepositFormChainAddress(props: Props) {
  const { chainAddress } = props
  const { address } = chainAddress
  const [isCopied, setCopied] = useClipboard(address, { successDuration: 3000 })

  return (
    <div>
      <div>
        <span
          css={css`
            display: inline-block;
            margin-bottom: 5px;
            color: #979696;
            font-size: 12px;
          `}
        >
          Address:
        </span>
      </div>
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <div>
          <Typography fontWeight={'bold'}>{address}</Typography>
        </div>

        <div>
          {isCopied ? (
            <Typography variant={'caption'}>Copied!</Typography>
          ) : (
            <div
              css={css`
                cursor: pointer;
                padding: 3px;
              `}
              onClick={setCopied}
            >
              <FontAwesomeIcon icon={faCopy} color={'#999999'} />
            </div>
          )}
        </div>
      </Grid>

      <Grid
        container
        justifyContent={'center'}
        css={css`
          margin-top: 20px;
          margin-bottom: 10px;
        `}
      >
        <QRCodeSVG value={address} size={240} />
      </Grid>
      <Typography textAlign={'center'}>Scan address using QR</Typography>

    </div>
  )
}

export default DepositFormChainAddress
