import apiClient from '@app/api/apiClient'

async function getList() {
  const { data } = await apiClient.get('/currency/list')
  return data
}

export default {
  getList,
}
