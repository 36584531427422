import { ReduxStateWallet } from '@app/reducers/wallet/reducer'
import React, { useContext } from 'react'
import { MarketFull } from '@app/typings/core'

export const OrderCreateContext = React.createContext<{
  market: MarketFull
  walletMain?: ReduxStateWallet
  walletSecondary?: ReduxStateWallet
}>({} as any) // market will be always set

export function useOrderCreateMarketContext() {
  const data = useContext(OrderCreateContext)
  return data
}

export default OrderCreateContext
