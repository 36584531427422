/** @jsx jsx */
import MarketDispatcher from '@app/reducers/markets/dispatcher'
import { jsx, css } from '@emotion/react'
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import { ReduxState } from '@reducers/index'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { useParams } from 'react-router-dom'
import { useQueryParam } from 'use-query-params'
import Loader from '../../components/Loader'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import TradeChart from '../trade/trade/TradeChart'

import '@app/socket/listenSocketEvents'
import useMarketSubscription from '@app/socket/hooks/useMarketSubscription'

function ChartPage() {
  const { key } = useParams<{ key: string }>()
  const dispatch = useDispatch<ReduxDispatch>()
  const [height] = useQueryParam<number>('height', {
    decode: (txt) => {
      return parseInt(txt as string) || undefined
    },
    encode: (t) => t,
  })

  const { market } = useSelector((state: ReduxState) => ({
    market: state.markets[key as string],
  }))
  const fetchMarket = useAsyncFetch(async () => {
    if (!key) return null
    await dispatch(MarketDispatcher.get({ market_key: key }))
  })

  useMarketSubscription(market?.key)

  return (
    <div
      css={css`
        min-height: 700px;
      `}
    >
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
      </Helmet>
      <RendererStatusSplit
        statuses={fetchMarket}
        isEmpty={!market}
        renderEmpty={() => (
          <Typography color="error" textAlign={'center'}>
            Market is not found
          </Typography>
        )}
        renderLoading={() => (
          <Grid container justifyContent={'center'}>
            <Loader />
          </Grid>
        )}
        render={() => <TradeChart market={market} height={height as number} />}
      />
    </div>
  )
}

export default ChartPage
