/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import CurrencyLogo from '../../../markets/components/CurrencyLogo'

interface Props {
  short_name: string
  name: string
  placeholder?: string
  logo?: string
  onClick(): any
}

function MockedInput(props: Props) {
  const { short_name, name, placeholder, logo, onClick } = props

  let isEmpty = !name

  return (
    <div
      onClick={onClick}
      css={css`
        border: 1px solid #ccc;
        padding: 10px 10px;
        border-radius: 7px;
        cursor: pointer;
      `}
    >
      <Grid container alignItems={'center'} justifyContent={'space-between'}>
        <Grid item>
          {isEmpty ? (
            <div>
              <Typography
                css={css`
                  color: #9fa09f;
                  padding-left: 5px;
                `}
              >
                {placeholder}
              </Typography>
            </div>
          ) : (
            <Grid container alignItems={'center'}>
              {logo && <CurrencyLogo currency={{ logo, name }} />}

              <Typography
                css={css`
                  margin-left: 7px;
                  margin-right: 7px;
                  font-weight: bold;
                `}
                fontWeight={'bold'}
              >
                {short_name}
              </Typography>
              <Typography>{name}</Typography>
            </Grid>
          )}
        </Grid>

        <Grid item>
          <FontAwesomeIcon icon={faChevronDown} color={'#ccc'} />
        </Grid>
      </Grid>
    </div>
  )
}

export default MockedInput
